import React, {
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from 'react';
import { spinnerStore } from '@Store/spinnerStore';
import { Button } from '@Components/Button';
import { dashboardQuery } from '../dashboard.query';
import NoResultsFound from '@Components/NoRecordsFound/NoResultsFound';
import classes from './../dashboard.module.scss';
import scrollToTop from 'utils/scrollToTop';
import { toast } from 'react-toastify';
import { TablePaginationGrid } from '../CardsGrid';
import AllRegisteredData from './AllRegisteredData/AllRegisteredData';
import Menu from '@mui/material/Menu';
import dayjs from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import 'dayjs/locale/en-in';
import RadioGroup from '@mui/material/RadioGroup';
import { StyledRadioControl } from '@Components/Radio';
import filterIcon from './../../../assets/dashboardICons/filter-icon.svg';
import commonLimit from 'common/commonLimits';
// import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
// Set the locale globally if available
dayjs.locale('en-in');
const AllRegisteredTab = ({}, ref) => {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [payload, setPayload] = useState('');
  const [searchPayload, setSearchPayload] = useState('');
  const [pageValue, setPageValue] = useState(1);
  const [allchecked, setAllChecked] = useState([]);
  const [searchString, setsearchString] = useState();
  const { setSpinner, isActive } = spinnerStore();
  const [open, setOpen] = React.useState(false);
  const getCurrentDate = () => {
    // const date = new Date();
    // const day = date.getDate();
    // const month = date.getMonth() + 1;
    // const year = date.getFullYear();
    // Create a new Date object for the current date
    const now = new Date();

    // Get the current year, month, and day
    const year = now.getFullYear();
    const month = now.getMonth() + 1; // Months are zero-based
    const day = now.getDate();

    // Format month and day to be two digits (e.g., "01", "09")
    const formattedMonth = month.toString().padStart(2, '0');
    const formattedDay = day.toString().padStart(2, '0');

    // Output the date in YYYY-MM-DD format
    const currentDate = `${year}-${formattedMonth}-${formattedDay}`;
    return currentDate;
  };
  const getStartDate = () => {
    const date = new Date();
    date.setDate(date.getDate() - commonLimit.installationDateBackDateinDays);
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    // Format month and day to be two digits (e.g., "01", "09")
    const formattedMonth = month.toString().padStart(2, '0');
    const formattedDay = day.toString().padStart(2, '0');

    // Output the date in YYYY-MM-DD format
    const currentDate = `${year}-${formattedMonth}-${formattedDay}`;

    return currentDate;
  };
  const formatDate = (date) => date.toISOString().split('T')[0];
  const [startDate, setStartDate] = useState(getStartDate());
  const [endDate, setEndDate] = useState(getCurrentDate());
  const [dateSelection, setDateSelection] = useState('lastweek');
  const handleChange = ({ target }) => {
    setDateSelection(target?.value || 'lastweek');
  };
  const [filter, setFilter] = useState(false);
  const handleApply = () => {
    filterCheck();
    if (dateSelection === 'thismonth') {
      setStartDate(handleThisMonth());
      // setFilter(true);
      setEndDate(getCurrentDate());
      setEndVal(dayjs().toISOString());
      setValue(dayjs().toISOString());
      handleClose();
    } else if (dateSelection === 'lastmonth') {
      setStartDate(handleLastMonth());
      setEndDate(handleLastmonthEndDate());
      setEndVal(dayjs().toISOString());
      setValue(dayjs().toISOString());
      // setFilter(true);
      handleClose();
    } else if (dateSelection === 'thisyear') {
      setStartDate(handleThisYear());
      setEndDate(getCurrentDate());
      setEndVal(dayjs().toISOString());
      setValue(dayjs().toISOString());
      // setFilter(true);
      handleClose();
    } else if (dateSelection === 'customDateRange') {
      setStartDate(value);
      setEndDate(endVal);
      handleClose();
    } else {
      setFilter(false);
    }
  };
  function filterCheck() {
    if (filter) {
      setFilter(false);
    } else {
      setFilter(true);
    }
  }
  // For unregistered data api call
  const { data, status, isSuccess, isRefetching } =
    dashboardQuery.useAllregisteredProducts(
      page + 1,
      rowsPerPage,
      searchPayload,
      pageValue,
      startDate,
      endDate,
      filter,
    );

  useEffect(() => {
    setSpinner(isRefetching);
  }, [isRefetching]);

  const isLoading = status === 'loading';
  useEffect(() => {
    if (isActive !== isLoading) {
      setSpinner(isLoading);
    }
  }, [isLoading, data, status]);
  useImperativeHandle(ref, () => ({
    showView: showView,
  }));

  function showView(recentData) {
    setsearchString(recentData);
    setPayload(recentData);
    setSearchPayload(recentData);
  }
  const selectedEquipment = (uneq) => {
    const existingData = allchecked.filter((item) => {
      return item === uneq;
    });
    if (existingData.length > 0) {
      const unCheckData = [];
      allchecked.forEach((item) => {
        if (item !== uneq) {
          unCheckData.push(item);
        }
      });
      setAllChecked(unCheckData);
    } else {
      if (allchecked.length >= 20) {
        toast.error(
          'Equipment selection  or registration  is limited to 10 at once.',
          {
            position: toast.POSITION.TOP_CENTER,
          },
        );
        return;
      }
      setAllChecked([...allchecked, uneq]);
    }
  };

  const handleChangePage = (event, newPage) => {
    if (payload !== '') {
      if (pageValue < data.totalPages) {
        setPageValue(pageValue + 1);
      }
    }

    if (payload !== '') {
      if (newPage < data?.pageNumber - 1) {
        // if (pageValue < data?.pageNumber - 1) {
        setPageValue(pageValue - 1);
      }
    }
    setPage(newPage);
    scrollToTop();
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    scrollToTop();
  };
  // const [value, setValue] = useState(dayjs(new Date()).format('YYYY-MM-DD'));
  const [value, setValue] = useState(dayjs().toISOString());
  const [endVal, setEndVal] = useState(dayjs().toISOString());

  const [calendar, setCalendar] = useState(false);
  const filterHandler = () => {
    setCalendar(true);
    if (calendar) {
      setCalendar(false);
    } else {
      setCalendar(true);
    }
  };
  const getStartOfSixMonthsAgo = () => {
    // Create a new Date object for the current date
    const now = new Date();
    // Calculate the date six months ago
    const sixMonthsAgo = new Date(now.setMonth(now.getMonth() - 6));
    return formatDate(sixMonthsAgo);
  };
  const handleThisMonth = () => {
    const now = new Date();
    const currentDate = new Date(now);
    const startDate = new Date(currentDate.setDate(1));
    const thismonth = formatDate(startDate);
    return thismonth;
  };
  const handleLastMonth = () => {
    const date = new Date();
    const currentDate = new Date(date);
    const lastMonthEndDate = new Date(currentDate.setDate(0));
    const startDate = new Date(lastMonthEndDate);
    startDate.setDate(1);
    return formatDate(startDate);
  };
  const handleLastmonthEndDate = () => {
    const date = new Date();
    const currentDate = new Date(date);
    const lastMonthEndDate = new Date(currentDate.setDate(0));
    return formatDate(lastMonthEndDate);
  };
  const handleThisYear = () => {
    const date = new Date();
    const currentDate = new Date(date);
    const thisYearDate = new Date(currentDate.setMonth(0));
    const startDate = new Date(thisYearDate.setDate(1));
    return formatDate(startDate);
  };

  const handleOpen = () => {
    setOpen(true);
  };
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open1 = Boolean(anchorEl);
  const handleClick = (event) => {
    // setFilter(false);
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div className={classes.allRegisteredTabCont}>
      <div className={classes.filterSection}>
        <button
          className={classes.filterBtn}
          // onClick={filterHandler}
          id="basic-button"
          aria-controls={open ? 'basic-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClick}
        >
          <img src={filterIcon} alt="filterIcon" />
          <span>Filter</span>
        </button>
      </div>
      {/* {calendar ? ( */}
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open1}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        className={classes.calendarSection}
      >
        <div className={classes.calendarInside}>
          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            name="radio-buttons-group"
            onChange={handleChange}
            value={dateSelection}
          >
            <div className={classes.radioBtnsWrapper}>
              <StyledRadioControl
                value="thismonth"
                label="This Month"
                onChange={handleThisMonth}
              />
              <StyledRadioControl
                value="lastmonth"
                label="Last month"
                onChange={handleLastMonth}
              />
              <br />
              <StyledRadioControl
                value="thisyear"
                label="This Year"
                onChange={handleThisYear}
              />

              <StyledRadioControl
                value="customDateRange"
                label="Custom Range"
              />
            </div>
          </RadioGroup>

          {dateSelection === 'customDateRange' ? (
            <div className={classes.pickersSection}>
              {/* <div className={classes.pickersSection}>
                <div className={classes.fromPicker}>
                  <p>From</p>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      views={['year', 'month', 'day']}
                      defaultValue={dayjs(new Date())}
                      placeholder="d/mm/yyy"
                      maxDate={dayjs(new Date())}
                      selected={value}
                      onChange={(value) => setValue(value.toISOString())}
                      // onClick={handleCalStartValue()}
                    />
                  </LocalizationProvider>
                </div>
                <div className={classes.toPicker}>
                  <p>To</p>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      views={['year', 'month', 'day']}
                      // defaultValue={dayjs(new Date())}
                      placeholder="d/mm/yyy"
                      maxDate={dayjs(new Date())}
                      selected={endVal}
                      onChange={(endVal) => setEndVal(endVal.toISOString())}
                    />
                  </LocalizationProvider>
                </div>
              </div> */}
              <div className={classes.fromPicker}>
                <p>From</p>
                <LocalizationProvider
                  dateAdapter={AdapterDayjs}
                  className={classes.dateSelect}
                >
                  <DatePicker
                    sx={{
                      color: '#D2D2D2',
                      borderRadius: '2px',
                      borderWidth: '1px',
                      borderColor: '#D2D2D2',
                      border: '1px solid',
                      backgroundColor: '#fff',
                      // minWidth: '80px',
                      // width: '50%',
                      // height: '38px',
                    }}
                    views={['year', 'month', 'day']}
                    defaultValue={dayjs(value)}
                    minDate={dayjs(getStartOfSixMonthsAgo())}
                    maxDate={dayjs(new Date())}
                    selected={value}
                    onChange={(value) => setValue(value.toISOString())}
                  />
                </LocalizationProvider>
              </div>
              <div className={classes.fromPicker}>
                <p>To</p>
                <LocalizationProvider
                  dateAdapter={AdapterDayjs}
                  className={classes.dateSelect}
                >
                  <DatePicker
                    sx={{
                      color: '#D2D2D2',
                      borderRadius: '2px',
                      borderWidth: '1px',
                      borderColor: '#D2D2D2',
                      border: '1px solid',
                      backgroundColor: '#fff',
                      // minWidth: '80px',
                      // width: '50%',
                      // height: '38px',
                    }}
                    views={['year', 'month', 'day']}
                    defaultValue={dayjs(endVal)}
                    minDate={dayjs(getStartOfSixMonthsAgo())}
                    maxDate={dayjs(new Date())}
                    selected={endVal}
                    onChange={(endVal) => setEndVal(endVal.toISOString())}
                  />
                </LocalizationProvider>
              </div>
            </div>
          ) : (
            <div></div>
          )}
          <div className={classes.popupActiveButtons}>
            <Button
              variant="outlined"
              color="secondary"
              onClick={() => {
                setAnchorEl(null);
              }}
              className={classes.cancelButton}
            >
              CANCEL
            </Button>
            <Button
              type="submit"
              className={classes.popupActiveButton}
              onClick={handleApply}
            >
              APPLY
            </Button>
          </div>
        </div>
      </Menu>
      {isSuccess && data && (
        <div className={classes.allRegisteredTabCont}>
          <div className={classes.middleContainer}>
            {data?.equipments.map((uneq) => (
              <>
                <AllRegisteredData
                  equipment={uneq}
                  key={uneq?.equipmentNumber}
                  selectedEquip={allchecked}
                  selectedEquipment={selectedEquipment}
                />
              </>
            ))}
          </div>
          {data?.totalRecords > 2 && (
            <div className={classes.pagination}>
              <TablePaginationGrid
                handleChangePage={handleChangePage}
                handleChangeRowsPerPage={handleChangeRowsPerPage}
                page={data?.pageNumber - 1}
                rowsPerPage={data?.pageSize}
                count={data?.totalRecords}
              />
            </div>
          )}
        </div>
      )}
      {isSuccess && !data && <NoResultsFound handleClick={handleOpen} />}
    </div>
  );
};

export default forwardRef(AllRegisteredTab);
