import React, { useEffect, useState } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Router from './routes';
import Spinner from './core/spinner/spinner';
import { useMsal } from '@azure/msal-react';
import getUserAccount from 'utils/getUserAccount';
import envStrings from 'common/EnvStrings';
function App() {
  const [value, setValue] = useState(false);
  const { accounts, instance } = useMsal();

  const reload = () => window.location.reload();
  const policyURL = localStorage.getItem('policyURL');
  useEffect(() => {
    const currentTime = new Date();
    const currentSec = new Date(currentTime).setMilliseconds(0) / 1000;
    const userAccount = getUserAccount();
    const tokenValue = userAccount?.exp - 12500 / 1000;
    let value = currentSec > tokenValue;
    setValue(value);

    if (value) {
      const accessTokenRequest = {
        account: accounts[0],
        scopes: envStrings.scope,

        // scopes: ['7e9f9a1e-0e38-4eb4-929c-927c38b5a349'], //UAT
        // scopes: ['91b9fae7-2bff-435a-a24e-da9cafff89af'], //Prod
        authority: envStrings.authority,
        // 'https://cxportal.b2clogin.com/tfp/cxportal.onmicrosoft.com/B2C_1A_LWCPORTAL_SIGNIN_POLICY_VSGPR',
        // 'https://cxportaluat.b2clogin.com/tfp/cxportaluat.onmicrosoft.com/B2C_1A_LWCPORTAL_SIGNIN_POLICY_VSGPR',
        // 'https://cxportalprod.b2clogin.com/tfp/cxportalprod.onmicrosoft.com/b2c_1a_lwcportal_signin_policy_vsgna_combined',
        // authority: policyURL,
        // authority:
        //   'https://cxportal.b2clogin.com/tfp/cxportal.onmicrosoft.com/B2C_1A_LWCPORTAL_SIGNIN_POLICY_VSGNA_COMBINED',
        // 'https://cxportaluat.b2clogin.com/tfp/cxportaluat.onmicrosoft.com/B2C_1A_LWCPORTAL_SIGNIN_POLICY_VSGNA_COMBINED',
        // 'https://cxportalprod.b2clogin.com/tfp/cxportalprod.onmicrosoft.com/b2c_1a_lwcportal_signin_policy_vsgna_combined',
      };
      instance
        .acquireTokenSilent(accessTokenRequest)
        .then((accessTokenResponse) => {
          // Acquire token silent success
          let accessToken = accessTokenResponse.idToken;
          const accountRaw = localStorage.getItem('userAccount');
          if (!accountRaw) return null;
          const account = JSON.parse(accountRaw);
          account.idToken = accessToken;
          account.idTokenClaims.exp =
            accessTokenResponse.account.idTokenClaims.exp;
          localStorage.setItem('userAccount', JSON.stringify(account));
        })
        .catch((error) => {
          alert(
            'We are redirecting to landing page as Session is expired! Please login again',
          );
          instance.logoutRedirect({
            idTokenHint: userAccount.idToken,
            authority: envStrings.authority,
            // 'https://cxportal.b2clogin.com/tfp/cxportal.onmicrosoft.com/B2C_1A_LWCPORTAL_SIGNIN_POLICY_VSGPR',
            // 'https://cxportaluat.b2clogin.com/tfp/cxportaluat.onmicrosoft.com/B2C_1A_LWCPORTAL_SIGNIN_POLICY_VSGPR',
            // 'https://cxportalprod.b2clogin.com/tfp/cxportalprod.onmicrosoft.com/b2c_1a_lwcportal_signin_policy_vsgna_combined',
          });

          localStorage.clear();
          sessionStorage.clear();
        });
    }
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      const currentTime = new Date();
      const currentSec = new Date(currentTime).setMilliseconds(0) / 1000;
      const userAccount = getUserAccount();
      const tokenValue = userAccount.exp - 12500 / 1000;
      let value = currentSec > tokenValue;
      setValue(value);

      if (value) {
        const accessTokenRequest = {
          account: accounts[0],
          scopes: envStrings.scope,
          // scopes: ['eb0f91d7-0179-411a-a357-0dfe7e9cff52'],
          // // scopes: ['7e9f9a1e-0e38-4eb4-929c-927c38b5a349'], //UAT
          // // scopes: ['91b9fae7-2bff-435a-a24e-da9cafff89af'], //Prod
          authority: envStrings.authority,
          // 'https://cxportal.b2clogin.com/tfp/cxportal.onmicrosoft.com/B2C_1A_LWCPORTAL_SIGNIN_POLICY_VSGPR',
          // 'https://cxportaluat.b2clogin.com/tfp/cxportaluat.onmicrosoft.com/B2C_1A_LWCPORTAL_SIGNIN_POLICY_VSGPR',
          // 'https://cxportalprod.b2clogin.com/tfp/cxportalprod.onmicrosoft.com/b2c_1a_lwcportal_signin_policy_vsgna_combined',
          // authority: policyURL,
          // 'https://cxportal.b2clogin.com/tfp/cxportal.onmicrosoft.com/B2C_1A_LWCPORTAL_SIGNIN_POLICY_VSGNA_COMBINED',
          // 'https://cxportaluat.b2clogin.com/tfp/cxportaluat.onmicrosoft.com/B2C_1A_LWCPORTAL_SIGNIN_POLICY_VSGNA_COMBINED',
          // 'https://cxportalprod.b2clogin.com/tfp/cxportalprod.onmicrosoft.com/b2c_1a_lwcportal_signin_policy_vsgna_combined',
        };
        instance
          .acquireTokenSilent(accessTokenRequest)
          .then((accessTokenResponse) => {
            // Acquire token silent success
            let accessToken = accessTokenResponse.idToken;

            const accountRaw = localStorage.getItem('userAccount');
            if (!accountRaw) return null;
            const account = JSON.parse(accountRaw);
            account.idToken = accessToken;
            account.idTokenClaims.exp =
              accessTokenResponse.account.idTokenClaims.exp;
            localStorage.setItem('userAccount', JSON.stringify(account));
          })
          .catch((error) => {
            alert('We are redirecting to landing page, Please login again');
            instance.logoutRedirect({
              idTokenHint: userAccount.idToken,
              authority: envStrings.authority,
              // 'https://cxportal.b2clogin.com/tfp/cxportal.onmicrosoft.com/B2C_1A_LWCPORTAL_SIGNIN_POLICY_VSGPR',
              // 'https://cxportaluat.b2clogin.com/tfp/cxportaluat.onmicrosoft.com/B2C_1A_LWCPORTAL_SIGNIN_POLICY_VSGPR',
              // 'https://cxportalprod.b2clogin.com/tfp/cxportalprod.onmicrosoft.com/b2c_1a_lwcportal_signin_policy_vsgna_combined',
            });
            // logout();
            localStorage.clear();
            sessionStorage.clear();
          });
      }
    }, 60000);
    //Clearing the interval
    return () => clearInterval(interval);
  }, [instance, accounts, value]);

  return (
    <BrowserRouter>
      <Router path="/apple-app-site-association" onEnter={reload} />
      <ToastContainer autoClose={5000} pauseOnFocusLoss={false} />
      <Spinner />
    </BrowserRouter>
  );
}

export default App;
