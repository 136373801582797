import React, { useState, useCallback, useEffect } from 'react';
import Typography from '@mui/material/Typography';
import { Button } from '@Components/Button';
import classes from '../MultiDetails.module.scss';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Grid from '@mui/material/Grid';
import Hidden from '@mui/material//Hidden';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import accordionMoreIcon from './../../../../../assets/dashboardICons/accordionMore.svg';
import accordionLessIcon from './../../../../../assets/dashboardICons/accordionLess.svg';
import accordionMoreColorIcon from './../../../../../assets/dashboardICons/accordionMoreColorIcon.svg';
import captureImg from './../../../../../assets/capture_vsg_ID.svg';
import upload from './../../../../../assets/download_vsg_ID.svg';
import Webcam from 'react-webcam';
import { useRef } from 'react';
import uploadDisabled from './../../../../../assets/dashboardICons/upload_disabled_grayBG.svg';
import CameraCaptureDisabled from './../../../../../assets/dashboardICons/CameraCapture_disabled_grayBG.svg';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContentText from '@mui/material/DialogContentText';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import switchCamera from '../../../../../assets/switchCamera.svg';
import { toast } from 'react-toastify';
const MultiViewDetails = ({ equipment: uneq, onChange }) => {
  const multiviewData = uneq;
  const hasextendedWarranty = multiviewData.hasextendedWarranty ?? false;
  const identificationNum = multiviewData.identificationNum ?? '';
  const imageData = multiviewData.image ?? false;
  //   const [selectedImage, setSelectedImage] = useState();
  //   const [multiviewdata, setMultiViewData] = useState(uneq);
  const [checked, setChecked] = React.useState(false);
  const [imgCheck, setImgCheck] = React.useState(false);
  const [uploadCheck, setUploadCheck] = useState(false);
  const [isShown, setIsShown] = React.useState(false);
  const [openAccordionId, setOpenAccordionId] = React.useState(null);
  const [inputValue, setInputValue] = useState('');
  const [selectedImage, setSelectedImage] = useState();
  const [open, setOpen] = useState(false);
  const inputRef = useRef();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const [openDailogue, setOpenDailogue] = useState(false);
  const handleChange = (e) => {
    if (multiviewData) {
      const isEnabled = !hasextendedWarranty;
      if (isEnabled) {
        const newMultiViewaData = {
          productCode: multiviewData?.productCode,
          equipmentNumber: multiviewData?.equipmentNumber,
          equipmentId: multiviewData?.equipmentId,
          identificationNum: identificationNum,
          image: imageData,
          hasextendedWarranty: isEnabled,
        };
        onChange(newMultiViewaData);
      } else {
        if (identificationNum || imageData) {
          setAlertOpen(true);
        } else {
          const newMultiViewaData = {
            productCode: multiviewData?.productCode,
            equipmentNumber: multiviewData?.equipmentNumber,
            equipmentId: multiviewData?.equipmentId,
            identificationNum: '',
            hasextendedWarranty: isEnabled,
          };
          onChange(newMultiViewaData);
        }
      }
    }
    setChecked(e.target.checked);
  };
  const handleAlertCheck = () => {
    const isEnabled = !hasextendedWarranty;
    const newMultiViewaData = {
      productCode: multiviewData?.productCode,
      equipmentNumber: multiviewData?.equipmentNumber,
      equipmentId: multiviewData?.equipmentId,
      identificationNum: '',
      hasextendedWarranty: isEnabled,
    };
    onChange(newMultiViewaData);
    setAlertOpen(false);
  };
  const handleAccordionChange = (itemId) => {
    setOpenAccordionId(itemId === openAccordionId ? null : itemId);
  };

  const [isValid, setIsValid] = useState(true); // To keep track of validity
  // Regex pattern for validation
  const regex = /^rl[a-zA-Z0-9]{2,10}$/i;
  const handleInputChange = (event) => {
    const value = event.target.value;
    if (value !== '') {
      regex.test(value) ? setIsValid(true) : setIsValid(false);
    } else {
      setIsValid(true);
    }
    // const inputValue = event.target.value;
    // if (inputValue || '') {
    const newMultiViewDatawithIdentynum = {
      productCode: multiviewData?.productCode,
      equipmentNumber: multiviewData?.equipmentNumber,
      equipmentId: multiviewData?.equipmentId,
      identificationNum: event.target.value,
      image: imageData,
      hasextendedWarranty: hasextendedWarranty,
    };
    onChange(newMultiViewDatawithIdentynum);
    // }
  };
  const [timestamp, setTimestamp] = useState(Date.now());

  useEffect(() => {
    // Update timestamp every second
    const interval = setInterval(() => {
      setTimestamp(Date.now());
    }, 1000);

    // Clean up interval on component unmount
    return () => clearInterval(interval);
  }, []);

  // Format the timestamp into a readable format
  const formattedDate = new Date(timestamp).toLocaleString();

  const webcamRef = useRef(null); // create a webcam reference
  const [imgSrc, setImgSrc] = useState(null); // initialize it

  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };
  const [uploadedImg, setUploadedImg] = useState();
  const handleupload = async (e) => {
    if (e.target.files.length === 0) {
      // No file selected, ignore\
      setUploadCheck(false);
      setSelectedImage(false);
      return;
    } else {
      const selectedImage = e.target.files[0];

      if (!selectedImage.type.startsWith('image/')) {
        toast.error('Please select an image file!', {
          position: toast.POSITION.TOP_CENTER,
        });
        //  setFileSize(null);
        return;
      }
      setUploadedImg(URL.createObjectURL(e.target.files[0]));
      const maxSizeInBytes = 5 * 1024 * 1024; // 5 MB
      if (selectedImage.size > maxSizeInBytes) {
        toast.error(
          'The selected warranty image exceeds the 5 MB size limit. Please choose a smaller file and try again.',
          {
            position: toast.POSITION.TOP_CENTER,
          },
        );
        //  setFileSize(null);
        return;
      }
      // const uploadImgURL = URL.createObjectURL(selectedImage);
      const uploadImgURL = await convertBase64(selectedImage);
      const imageSplit = uploadImgURL.split(',');
      const namesplit = selectedImage.name.split('.');
      let fileName =
        namesplit[0] + multiviewData?.equipmentNumber + '_' + timestamp;
      namesplit.forEach((item, index) => {
        if (index > 0) {
          fileName = fileName + '.' + item;
        }
      });
      const imageDetails = {
        name: fileName,
        size: selectedImage.size,
        type: selectedImage.type,
        uri: imageSplit[1],
      };
      setSelectedImage(true);
      const newMultiViewaData = {
        productCode: multiviewData?.productCode,
        equipmentNumber: multiviewData?.equipmentNumber,
        equipmentId: multiviewData?.equipmentId,
        identificationNum: identificationNum,
        image: imageDetails,
        hasextendedWarranty: hasextendedWarranty,
      };
      onChange(newMultiViewaData);
      setUploadCheck(true);
    }
  };
  const removeSelectedImage = () => {
    // setSelectedImage();
    const newMultiViewaData = {
      productCode: multiviewData?.productCode,
      equipmentNumber: multiviewData?.equipmentNumber,
      equipmentId: multiviewData?.equipmentId,
      identificationNum: identificationNum,
      image: false,
      hasextendedWarranty: hasextendedWarranty,
    };
    setSelectedImage();
    onChange(newMultiViewaData);
  };
  // const handleClickOpen = () => {
  //   setOpenDailogue(true);
  // };

  const handleClose1 = () => {
    setOpenDailogue(false);
  };
  const FACING_MODE_USER = 'user';
  const FACING_MODE_ENVIRONMENT = 'environment';

  const videoConstraints = {
    facingMode: FACING_MODE_USER,
  };
  const [facingMode, setFacingMode] = React.useState(FACING_MODE_USER);

  const handleClick = React.useCallback(() => {
    setFacingMode((prevState) =>
      prevState === FACING_MODE_USER
        ? FACING_MODE_ENVIRONMENT
        : FACING_MODE_USER,
    );
  }, []);
  const captureTheimage = () => {
    // setOpen(true);
    setOpenDailogue(true);
    setImgCheck(true);
  };
  const capture = () => {
    setOpenDailogue(false);
    const imageSrc = webcamRef.current.getScreenshot();
    setUploadedImg(imageSrc);
    setImgSrc(imageSrc);
    const selectedImage = webcamRef.current.getScreenshot();
    const imageSplit = selectedImage.split(',');
    // const uploadImgURL = URL.createObjectURL(imageSrc);
    const imageDetails = {
      name: multiviewData?.equipmentNumber + '+' + timestamp + '.jpeg',
      size: webcamRef.current.props.screenshotQuality,
      type: webcamRef.current.props.screenshotFormat,
      uri: imageSplit[1],
    };
    const isEnabled = !hasextendedWarranty;
    // if (isEnabled) {
    const newMultiViewaData = {
      productCode: multiviewData?.productCode,
      equipmentNumber: multiviewData?.equipmentNumber,
      equipmentId: multiviewData?.equipmentId,
      identificationNum: identificationNum,
      image: imageDetails,
      hasextendedWarranty: hasextendedWarranty,
    };
    onChange(newMultiViewaData);
    // }

    const captureImg = imageSrc ? true : false;
    setSelectedImage(captureImg);
  };

  const removeCaptureImage = () => {
    setImgSrc(null);
    setImgCheck(false);
    setSelectedImage();
  };
  const cancelCapture = () => {
    setImgSrc();
  };
  const retake = () => {
    setImgSrc(null);
  };
  const shouldDisablePickers = () => {
    if (hasextendedWarranty) {
      if (imageData) {
        return true;
      }
      return false;
    } else {
      return true;
    }
  };
  const [alertOpen, setAlertOpen] = useState(false);
  const handleClose = () => {
    setAlertOpen(false);
  };
  const [imageOpen, setImageOpen] = React.useState(false);

  const handleClickOpen = () => {
    setImageOpen(true);
  };
  const handleCloseImg = () => {
    setImageOpen(false);
  };
  return (
    <div>
      <>
        <Hidden mdDown>
          <div className={classes.multiDetailsBox}>
            <Box sx={{ flexGrow: 1 }}>
              <Grid container spacing={2}>
                <input
                  type="checkbox"
                  onChange={handleChange}
                  className={classes.checkboxStyle}
                  //   value={uneq?.equipmentNumber}
                  checked={hasextendedWarranty}
                />
                <Grid item xs={6} md={2} sm={3}>
                  <p className={classes.subDetails}>
                    {multiviewData?.productCode}
                  </p>
                </Grid>
                <Grid item xs={6} md={2} sm={3}>
                  <p className={classes.subDetails}>
                    {multiviewData?.equipmentNumber}
                  </p>
                </Grid>

                <Grid item xs={12} md={3} sm={6}>
                  <input
                    type="text"
                    value={identificationNum}
                    onChange={handleInputChange}
                    disabled={!hasextendedWarranty}
                    style={{
                      borderColor: isValid ? '#D2D2D2' : '#CE1F1F',
                      color: isValid ? '#000' : '#CE1F1F',
                    }}
                    // ref={inputRef}
                  />
                  {!isValid && (
                    <p className={classes.invalidInput}>
                      The warranty certificate ID is invalid. It must be between
                      4 and 12 characters long, start with "RL," and contain
                      only alphanumeric characters. Please correct the ID and
                      try again.
                    </p>
                  )}
                </Grid>
                <Grid item xs={12} md={3} sm={6}>
                  <>
                    <div className={classes.wrapper}>
                      <div className={classes.btnimg}>
                        {' '}
                        {!imageData && (
                          <img
                            src={upload}
                            alt="upload Identification number"
                            disabled={imageData.uri ? true : false}
                          />
                        )}
                        {imageData && (
                          <img
                            src={uploadDisabled}
                            alt="disabled upload Identification number"
                            className={classes.disabledImg}
                          />
                        )}
                      </div>
                      <input
                        type="file"
                        onChange={handleupload}
                        // id={uneq?.equipmentNumber}
                        accept=".jpg,.jpeg,.png,.gif" // Restrict file types
                        // disabled={imageData?.uri ? true : false}
                        disabled={shouldDisablePickers()}
                      ></input>
                      <button
                        onClick={captureTheimage}
                        className={classes.captureImage}
                        // disabled={imageData?.uri ? true : false}
                        disabled={shouldDisablePickers()}
                      >
                        {!imageData && (
                          <img
                            src={captureImg}
                            alt="capture identification number"
                          />
                        )}
                        {imageData && (
                          <img
                            src={CameraCaptureDisabled}
                            alt="camera capture disabled number"
                            className={classes.disabledImg}
                          />
                        )}
                      </button>

                      {/* {imageData && (
                        <div
                          className={
                            imageData
                              ? classes.selectedImg
                              : classes.unselectedImg
                          }
                        >
                          <p className={classes.hoverText}>
                            <h2 className={classes.rotaryTitle}>
                              {imageData.name}
                            </h2>
                            <span className={classes.tooltip}>
                              {imageData.name}
                            </span>
                          </p>

                          <button
                            onClick={removeSelectedImage}
                            className={classes.removeImg}
                          >
                            X
                          </button>
                        </div>
                      )} */}

                      {imageData && (
                        <div
                          className={
                            imageData
                              ? classes.selectedImg
                              : classes.unselectedImg
                          }
                        >
                          {/* <img src={imageData.uri ?? ''} alt="Thumb" />
                           */}
                          <button
                            // onClick={imageViewerClick}
                            className={classes.imageViewer}
                            variant="outlined"
                            onClick={handleClickOpen}
                          >
                            <p className={classes.hoverText}>
                              <h2 className={classes.rotaryTitle}>
                                {imageData.name}
                              </h2>
                              <span className={classes.tooltip}>
                                {imageData.name}
                              </span>
                            </p>
                            {/* <p>{imageData.name}</p> */}
                          </button>
                          <button
                            onClick={removeSelectedImage}
                            className={classes.removeImg}
                          >
                            X
                          </button>
                        </div>
                      )}
                      <Dialog
                        open={imageOpen}
                        onClose={handleCloseImg}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                      >
                        <DialogContent>
                          <DialogContentText id="alert-dialog-description">
                            <img
                              src={uploadedImg}
                              alt="Thumb"
                              className={classes.imageView}
                            />
                          </DialogContentText>
                        </DialogContent>
                      </Dialog>

                      <div className={classes.selectedCaptureImg}>
                        {/* {imgCheck ? (
                          imgSrc ? (
                            <>
                              <div className={classes.selectedImg}>
                                <p>CaptureImage.jpeg</p>
                                <button
                                  onClick={removeCaptureImage}
                                  className={classes.removeImg}
                                >
                                  X
                                </button>
                              </div>
                            </>
                          ) : (
                            <Webcam
                              height={200}
                              width={300}
                              ref={webcamRef}
                              screenshotFormat="image/jpeg"
                              screenshotQuality={0.8}
                            />
                          )
                        ) : null} */}

                        {/* <div className={classes.popupActiveButtonss}>
                          {imgCheck ? (
                            imgSrc ? null : ( // <button onClick={retake}>Retake photo</button>
                              <>
                                <button
                                  onClick={capture}
                                  className={classes.popupActiveButton}
                                >
                                  Capture
                                </button>
                                <button
                                  onClick={removeCaptureImage}
                                  className={classes.cancelButton}
                                >
                                  Cancel
                                </button>
                              </>
                            )
                          ) : null}
                        </div> */}
                      </div>

                      <Dialog
                        fullScreen={fullScreen}
                        open={openDailogue}
                        onClose={handleClose1}
                        aria-labelledby="responsive-dialog-title"
                      >
                        <DialogTitle
                          id="responsive-dialog-title"
                          className={classes.dailogHeader}
                        >
                          {'Capture the Image'}
                        </DialogTitle>
                        <DialogContent>
                          <DialogContentText className={classes.dailogCont}>
                            <Webcam
                              height={200}
                              width={300}
                              ref={webcamRef}
                              screenshotFormat="image/jpeg"
                              screenshotQuality={0.8}
                            />
                          </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                          <Button
                            onClick={handleClose1}
                            className={classes.dailogButtonCancel}
                          >
                            Cancel
                          </Button>
                          <Button
                            className={classes.dailogButton}
                            onClick={capture}
                          >
                            Capture
                          </Button>
                        </DialogActions>
                      </Dialog>
                      {/* </Box>
                      </Modal> */}
                    </div>
                  </>
                </Grid>
              </Grid>
            </Box>
          </div>
        </Hidden>
        <Hidden mdUp>
          <div className={classes.rotaryCardContainerMobile}>
            <Accordion
              className={classes.headingSection}
              expanded={openAccordionId === multiviewData.equipmentNumber}
              onChange={() => handleAccordionChange(uneq.equipmentNumber)}
            >
              <div
                className={
                  openAccordionId === uneq.equipmentNumber
                    ? classes.headingDetailsFocus
                    : classes.headingDetails
                }
              >
                <AccordionSummary
                  expandIcon={
                    openAccordionId === multiviewData.equipmentNumber ? (
                      <img
                        src={accordionMoreColorIcon}
                        alt="accordionLessIcon"
                      />
                    ) : isShown ? (
                      <img
                        src={accordionMoreColorIcon}
                        alt="accordionLessIcon"
                      />
                    ) : !isShown ? (
                      <img
                        src={accordionMoreIcon}
                        alt="accordionMoreIcon"
                        className={classes.iconStyles}
                      />
                    ) : (
                      <img src={accordionLessIcon} alt="accordionLessIcon" />
                    )
                  }
                  onMouseEnter={() => setIsShown(true)}
                  onMouseLeave={() => setIsShown(false)}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  className={classes.accordionExpand}
                >
                  <div className={classes.rotaryHeading}>
                    <Box sx={{ flexGrow: 1 }}>
                      <Grid
                        container
                        spacing={2}
                        className={classes.rotaryHeadingText}
                      >
                        {' '}
                        <input
                          // value="test"
                          type="checkbox"
                          onChange={handleChange}
                          className={classes.checkboxStyle}
                          checked={hasextendedWarranty}
                        />
                        <Grid
                          item
                          xs={12}
                          md={4}
                          className={classes.rotaryHeadingCont}
                        >
                          <p className={classes.subHead}>Serial Number</p>
                          <p className={classes.subDetails}>
                            {uneq?.equipmentNumber}
                          </p>
                        </Grid>
                      </Grid>
                    </Box>
                  </div>
                </AccordionSummary>
              </div>
              <AccordionDetails style={{ border: '1.5px solid #CF8686' }}>
                <Typography>
                  <div className={classes.cardDetailSection}>
                    <Box sx={{ flexGrow: 1 }}>
                      <Grid
                        container
                        spacing={2}
                        className={classes.rotaryHeadingText}
                      >
                        {/* <Grid
                          item
                          xs={12}
                          md={4}
                          className={classes.rotaryHeadingCont}
                        >
                          <p className={classes.subHead}>Serial Number</p>
                          <p className={classes.subDetails}>
                            {uneq?.equipmentNumber}
                          </p>
                        </Grid> */}
                        <Grid item xs={12} md={4}>
                          <p className={classes.subHead}>Product Code</p>
                          <p className={classes.subDetails}>
                            {uneq?.productCode}
                          </p>
                        </Grid>

                        <Grid item xs={12} md={3} sm={6}>
                          <p className={classes.subHead}>
                            Identification Number
                          </p>
                          <p className={classes.subDetails}>
                            <input
                              type="text"
                              value={identificationNum}
                              onChange={handleInputChange}
                              disabled={!hasextendedWarranty}
                              style={{
                                borderColor: isValid ? '#D2D2D2' : '#CE1F1F',
                                color: isValid ? '#000' : '#CE1F1F',
                              }}
                            />
                            {!isValid && (
                              <p className={classes.invalidInput}>
                                The warranty certificate ID is invalid. It must
                                be between 4 and 12 characters long, start with
                                "RL," and contain only alphanumeric characters.
                                Please correct the ID and try again.
                              </p>
                            )}
                          </p>
                        </Grid>
                        {/* <Grid item xs={12} md={3} sm={6}>
                          <p className={classes.subHead}>
                            Please Upload/Capture warranty certificate
                          </p>
                          <p className={classes.subDetails}>
                            <div className={classes.wrapper}>
                              <div className={classes.btnimg}>
                                {' '}
                                <img
                                  src={upload}
                                  alt="upload Identification number"
                                />
                              </div>
                              <input
                                type="file"
                                onChange={handleupload}
                                // id={uneq?.equipmentNumber}
                                accept="image/*"
                                disabled={shouldDisablePickers()}
                              ></input>
                              <button
                                onClick={captureTheimage}
                                className={classes.captureImage}
                                disabled={!hasextendedWarranty}
                              >
                                <img
                                  src={captureImg}
                                  alt="capture identification number"
                                />
                              </button>

                              {imageData && (
                                <div
                                  className={
                                    imageData
                                      ? classes.selectedImg
                                      : classes.unselectedImg
                                  }
                                >
                                  
                                  <p className={classes.hoverText}>
                                    <h2 className={classes.rotaryTitle}>
                                      {imageData.name}
                                    </h2>
                                    <span className={classes.tooltip}>
                                      {imageData.name}
                                    </span>
                                  </p>
                                  <button
                                    onClick={removeSelectedImage}
                                    className={classes.removeImg}
                                  >
                                    X
                                  </button>
                                </div>
                              )}

                              <div className={classes.selectedCaptureImg}>
                                {imgCheck ? (
                                  imgSrc ? (
                                    <>
                                      <button
                                        onClick={removeCaptureImage}
                                        className={classes.removecaptureImg}
                                      >
                                        X
                                      </button>
                                      <img src={imgSrc} alt="webcam" />
                                    </>
                                  ) : (
                                    <Webcam
                                      height={200}
                                      width={300}
                                      ref={webcamRef}
                                      screenshotFormat="image/jpeg"
                                      screenshotQuality={0.8}
                                    />
                                  )
                                ) : null}
                                <div className={classes.popupActiveButtons}>
                                  {imgCheck ? (
                                    imgSrc ? null : ( // <button onClick={retake}>Retake photo</button>
                                      <>
                                        <button
                                          onClick={capture}
                                          className={classes.popupActiveButton}
                                        >
                                          Capture
                                        </button>
                                        <button
                                          onClick={removeCaptureImage}
                                          className={classes.cancelButton}
                                        >
                                          Cancel
                                        </button>
                                      </>
                                    )
                                  ) : null}
                                </div>
                              </div>
                            </div>
                          </p>
                        </Grid> */}
                        <Grid item xs={12} md={3} sm={6}>
                          <>
                            <div className={classes.wrapper}>
                              <div className={classes.btnimg}>
                                {' '}
                                {!imageData && (
                                  <img
                                    src={upload}
                                    alt="upload Identification number"
                                    disabled={imageData.uri ? true : false}
                                  />
                                )}
                                {imageData && (
                                  <img
                                    src={uploadDisabled}
                                    alt="disabled upload Identification number"
                                    className={classes.disabledImg}
                                  />
                                )}
                              </div>
                              <input
                                type="file"
                                onChange={handleupload}
                                accept="image/*"
                                disabled={shouldDisablePickers()}
                              ></input>
                              <button
                                onClick={captureTheimage}
                                className={classes.captureImage}
                                // disabled={imageData?.uri ? true : false}
                                disabled={shouldDisablePickers()}
                              >
                                {!imageData && (
                                  <img
                                    src={captureImg}
                                    alt="capture identification number"
                                  />
                                )}
                                {imageData && (
                                  <img
                                    src={CameraCaptureDisabled}
                                    alt="camera capture disabled number"
                                    className={classes.disabledImg}
                                  />
                                )}
                              </button>

                              {imageData && (
                                <div
                                  className={
                                    imageData
                                      ? classes.selectedImg
                                      : classes.unselectedImg
                                  }
                                >
                                  {/* <img src={imageData.uri ?? ''} alt="Thumb" />
                                   */}
                                  <button
                                    // onClick={imageViewerClick}
                                    className={classes.imageViewer}
                                    variant="outlined"
                                    onClick={handleClickOpen}
                                  >
                                    <p className={classes.hoverText}>
                                      <h2 className={classes.rotaryTitle}>
                                        {imageData.name}
                                      </h2>
                                      <span className={classes.tooltip}>
                                        {imageData.name}
                                      </span>
                                    </p>
                                    {/* <p>{imageData.name}</p> */}
                                  </button>
                                  <button
                                    onClick={removeSelectedImage}
                                    className={classes.removeImg}
                                  >
                                    X
                                  </button>
                                </div>
                              )}
                              <Dialog
                                open={imageOpen}
                                onClose={handleCloseImg}
                                aria-labelledby="alert-dialog-title"
                                aria-describedby="alert-dialog-description"
                              >
                                <DialogContent>
                                  <DialogContentText id="alert-dialog-description">
                                    <img src={uploadedImg} alt="Thumb" />
                                  </DialogContentText>
                                </DialogContent>
                              </Dialog>
                              {/* <Modal
                        open={open}
                        // onClose={handleClosePop}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                      >
                        <Box
                          // sx={style}
                          className={classes.RegisterEquipt_Modal}
                        > */}
                              <div className={classes.selectedCaptureImg}>
                                {/* {imgCheck ? (
                          imgSrc ? (
                            <>
                              <div className={classes.selectedImg}>
                                <p>CaptureImage.jpeg</p>
                                <button
                                  onClick={removeCaptureImage}
                                  className={classes.removeImg}
                                >
                                  X
                                </button>
                              </div>
                            </>
                          ) : (
                            <Webcam
                              height={200}
                              width={300}
                              ref={webcamRef}
                              screenshotFormat="image/jpeg"
                              screenshotQuality={0.8}
                            />
                          )
                        ) : null} */}

                                {/* <div className={classes.popupActiveButtonss}>
                          {imgCheck ? (
                            imgSrc ? null : ( // <button onClick={retake}>Retake photo</button>
                              <>
                                <button
                                  onClick={capture}
                                  className={classes.popupActiveButton}
                                >
                                  Capture
                                </button>
                                <button
                                  onClick={removeCaptureImage}
                                  className={classes.cancelButton}
                                >
                                  Cancel
                                </button>
                              </>
                            )
                          ) : null}
                        </div> */}
                              </div>

                              <Dialog
                                fullScreen={fullScreen}
                                open={openDailogue}
                                onClose={handleClose1}
                                aria-labelledby="responsive-dialog-title"
                                className={classes.captureScreen}
                              >
                                <DialogTitle
                                  id="responsive-dialog-title"
                                  className={classes.dailogHeader}
                                >
                                  {'Capture the Image'}
                                </DialogTitle>
                                <DialogContent>
                                  <DialogContentText
                                    className={classes.dailogCont}
                                  >
                                    {/* <Webcam
                                      height={200}
                                      width={300}
                                      ref={webcamRef}
                                      screenshotFormat="image/jpeg"
                                      screenshotQuality={0.8}
                                    /> */}
                                    <Webcam
                                      className={classes.webCam}
                                      // height={200}
                                      // width={430}
                                      ref={webcamRef}
                                      screenshotFormat="image/jpeg"
                                      screenshotQuality={0.8}
                                      videoConstraints={{
                                        ...videoConstraints,
                                        facingMode,
                                      }}
                                    />
                                    <button
                                      onClick={handleClick}
                                      className={classes.switchCamerabtn}
                                    >
                                      <img
                                        src={switchCamera}
                                        alt="switchCamera"
                                        className={classes.switchCamera}
                                      />
                                    </button>
                                  </DialogContentText>
                                </DialogContent>
                                <DialogActions>
                                  <Button
                                    onClick={handleClose1}
                                    className={classes.dailogButtonCancel}
                                  >
                                    Cancel
                                  </Button>
                                  <Button
                                    className={classes.dailogButton}
                                    onClick={capture}
                                  >
                                    Capture
                                  </Button>
                                </DialogActions>
                              </Dialog>
                              {/* </Box>
                      </Modal> */}
                            </div>
                          </>
                        </Grid>
                      </Grid>
                    </Box>
                  </div>
                </Typography>
              </AccordionDetails>
            </Accordion>
          </div>
        </Hidden>
      </>
      <Dialog
        open={alertOpen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            'Warning! Data will be removed. Would you like to proceed?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} className={classes.dailogButtonCancel}>
            Cancel
          </Button>
          <Button onClick={handleAlertCheck} className={classes.dailogButton}>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default MultiViewDetails;
