import * as React from 'react';
import TablePagination from '@mui/material/TablePagination';
import './CardGrid.css';

export default function TablePaginationGrid({
  page,
  handleChangePage,
  handleChangeRowsPerPage,
  rowsPerPage,
  count,
}) {
  return (
    <TablePagination
      component="div"
      count={count}
      page={page}
      onPageChange={handleChangePage}
      rowsPerPage={rowsPerPage}
      rowsPerPageOptions={[5, 10, 15]}
      onRowsPerPageChange={handleChangeRowsPerPage}
      nextIconButtonProps={{ size: 'small' }}
    />
  );
}
