/* eslint-disable no-console */
import React, { useEffect } from 'react';
import classes from './NonRAI.module.scss';
import { MappedInput } from '@Components/Input';
import { formFeilds, useFormHook } from './NonRAI.form.initializer';
import useButtonsState from 'features/Installation/hooks/useButtonsState';
import useClickHandlers from 'features/Installation/hooks/useClickHandlers';
import { installationStore } from '@Store/installationStore';
import { shallow } from 'zustand/shallow';

function NonRotaryAuthorizedInstaller() {
  const {
    setInstallationType,
    setStepPrev,
    setNextButton,
    setInstallationRAIZipCode,
  } = installationStore();
  const installationType = installationStore(
    (state) => state.installationType,
    shallow,
  );
  const isExistingStep = installationType?.id === 2;
  useEffect(() => {
    setNextButton(true);
    if (!form.values.companyName) {
      form.setFieldValue('installerNumber', '');
      form.setFieldValue('zip', '');
    }
  }, []);

  const onSubmit = (values) => {
    setInstallationType({ ...values, id: 2 }, true);
  };

  const form = useFormHook(onSubmit, installationType);
  const { touched, isValid, error } = form;
  useButtonsState(touched, isValid, isExistingStep);
  useEffect(() => {
    if (installationType && isValid) {
      setNextButton(false);
    } else {
      setNextButton(true);
    }
  }, [installationType, isValid]);
  const handleNextClick = () => {
    setInstallationRAIZipCode('');
    form.submitForm();
  };
  const handlePrevClick = () => setStepPrev();
  useClickHandlers(handleNextClick, handlePrevClick);
  return (
    <div className={classes.NonRAInstallationCont}>
      <div className={classes.instalMiddleRightCont}>
        <div className={classes.installRightCont}>
          <div className={classes.Register_rightContainer}>
            <form onSubmit={form.handleSubmit}>
              <MappedInput fields={formFeilds} form={form} />
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default React.memo(NonRotaryAuthorizedInstaller);
