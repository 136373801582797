import React, { useEffect, useMemo, useState } from 'react';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import classes from './FindLocationDetails.module.scss';
import { instalationQuery } from 'features/Installation/Installation.query';
import { MappedInput } from '@Components/Input';
import { Button } from '@Components/Button';
import SearchResults from '@Components/SearchResults';
import { formFeilds, useFormHook } from './FindLocation.form.initializer';
import useClickHandlers from 'features/Installation/hooks/useClickHandlers';
import { installationStore } from '@Store/installationStore';
import { getUserSFDetails } from 'utils/getUserAccount';
import { spinnerStore } from '@Store/spinnerStore';
import { toast } from 'react-toastify';

function FindLocationDetails() {
  const [payload, setPayload] = useState({ name: '', zip: '', userId: '' });
  const [searchResultsId, setSearchResultsId] = useState('');
  const [valueCheck, setValueCheck] = useState(false);
  //alert for redirecting
  useEffect(() => {
    const unloadCallback = (event) => {
      event.preventDefault();
      event.returnValue = '';
      return '';
    };
    window.addEventListener('beforeunload', unloadCallback);
    return () => window.removeEventListener('beforeunload', unloadCallback);
  }, []);

  const {
    setLocationDetails,
    setFindLocation,
    setStepPrev,
    setNextButton,
    findLocation,
    locationDetails,
    setNewLocation,
    setNewLocationDetails,
  } = installationStore();
  localStorage.removeItem('businessValue');
  const { setSpinner, isActive } = spinnerStore();
  const [loadingFlag, setLoadingFlag] = useState(false);
  const [fetch, { data, isSuccess, status }] =
    instalationQuery.useInstallersByNameOrZip(payload);
  const [toastValve, setToastValue] = useState(true);
  const endUserDetails = installationStore((state) => state.endUserDetails);
  const userSFData = getUserSFDetails();
  let userId = userSFData.id;

  if (endUserDetails?.id) {
    userId = endUserDetails?.id;
  }
  const onSubmit = (values) => {
    setSearchResultsId('');
    setPayload({
      // userId,
      userId: userSFData.id,
      name: values.locationName,
      zip: values.locationZip,
    });

    setTimeout(() => {
      fetch();
    }, 100);
    setLoadingFlag(true);
    setValueCheck(true);

    if (data?.response !== null) {
      setNextButton(true);
    }
  };

  useEffect(() => {
    if (status === 'success') {
      setLoadingFlag(false);
    }
  }, [status === 'success']);
  const form = useFormHook(onSubmit);
  const { touched, values } = form;
  // const isTouched = Object.keys(touched).length !== 0;
  const handleChangeResults = ({ target }) => {
    setSearchResultsId(target.value);
    setNextButton(false);
  };

  useEffect(() => {
    if (
      (findLocation.locationZip || findLocation.locationName) &&
      locationDetails.id
    ) {
      form.setValues({
        ...values,
        ...findLocation,
      });
      setToastValue(false);
      onSubmit(findLocation);
      setSearchResultsId(locationDetails.id);
      setLoadingFlag(true);
      setNextButton(false);
    }
  }, []);
  useEffect(() => {
    if (
      (data?.response !== null || data?.response === null) &&
      values.locationZip === '' &&
      status === 'success'
    ) {
      setValueCheck(false);
      setSearchResultsId('');
    }
  }, [values, status, data?.response]);

  useEffect(() => {
    if (
      data?.message !== 'userlocationsfetchedsuccessfully' &&
      data?.response !== null
    ) {
      if (
        data?.message !==
        'Multiple zip code inputs limit reached. Please enter valid Business ZIP Code'
      ) {
        toast.error(data?.message, {
          position: toast.POSITION.TOP_CENTER,
        });
      }
    }
  }, [data?.message, toastValve]);
  // useEffect(() => {
  //   if (toastValve === true) {
  //     toast.error(data?.message, {
  //       position: toast.POSITION.TOP_CENTER,
  //     });
  //   }
  //   // setToastValue(false);
  // }, [toastValve === true]);

  // useEffect(() => {
  //   if (data?.message === 'Multiple zip code inputs limit reached') {
  //     setToastValue(true);
  //   }
  // }, [data?.message === 'Multiple zip code inputs limit reached']);
  // useEffect(() => {
  //   if (toastValve === true) {
  //     toast.error(data?.message, {
  //       position: toast.POSITION.TOP_CENTER,
  //     });
  //   }
  //   // setToastValue(false);
  // }, [toastValve === true]);

  const getItemById = (id) =>
    data?.response.find((ele) => String(ele.id) === String(id));

  const handleNextClick = () => {
    const selectedItem = getItemById(searchResultsId);
    setLocationDetails(selectedItem, true);
    setFindLocation(values);
    setNewLocation({}); // recently added
    setNewLocationDetails({}); // recently added
    // setToastValue(false);
  };

  const handlePrevClick = () => {
    setStepPrev();
    form.setFieldValue('locationZip', '');
    setFindLocation('');
  };

  useClickHandlers(handleNextClick, handlePrevClick);

  const props = useMemo(() => ({
    items: data?.response,
    title: 'Search results',
    handleChange: handleChangeResults,
    keys: ['name', 'address1', 'city', 'state', 'country'],
    selectRowId: searchResultsId,
  }));
  useEffect(() => {
    if (
      props.selectRowId ||
      (props.selectRowId && touched.locationName === true)
    ) {
      setNextButton(false);
    }
  }, [props.selectRowId, touched.locationName === true]);
  useEffect(() => {
    if ((!data?.response || data?.response) && values.locationZip === '') {
      setNextButton(true);
    }
  }, [data?.response, values.locationZip === '']);
  useEffect(() => {
    // if (isActive !== isLoading && !data) {
    setSpinner(loadingFlag === true);
    // }
    if ((data?.response || !data?.response) && isSuccess) {
      setSpinner('');
    }
  }, [loadingFlag]);

  return (
    <div className={classes.LocationDetailCont}>
      <div className={classes.FindLocationCont}>
        <div className={classes.locationFind}>
          <form onSubmit={form.handleSubmit}>
            <div className={classes.findLocationFormCont}>
              <MappedInput fields={formFeilds} form={form} />
              <div className={classes.findLocationButton}>
                <Button
                  className={classes.findBtn}
                  type="submit"
                  disabled={values.locationZip === ''}
                >
                  FIND
                </Button>
              </div>
            </div>

            {/* {data?.response &&
              isSuccess &&
              values.locationZip !== '' &&
              valueCheck === true &&
              data?.message === 'User locations fetched successfully' && (
                <Alert severity="error" style={{ margin: '5px 0px 5px 0px' }}>
                  {data?.message}
                </Alert>
              )} */}
            <div className={classes.locationResults}>
              {data?.response &&
                isSuccess &&
                values.locationZip !== '' &&
                valueCheck === true && (
                  /* Poojitha
                values.locationZip !== '' is used to hide the dropdown. When values.location is empty
                 */
                  <SearchResults {...props} />
                )}
            </div>
            {!data?.response &&
              isSuccess &&
              values.locationZip !== '' &&
              valueCheck === true && (
                <Stack
                  sx={{
                    width: '100%',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                  spacing={2}
                >
                  <Alert severity="error">
                    {data?.message}
                    {/* No locations found, Please enter a Business ZIP Code */}
                  </Alert>
                </Stack>
              )}
          </form>
        </div>
      </div>
    </div>
  );
}

export default FindLocationDetails;
