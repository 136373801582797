import React, { useEffect, useState } from 'react';
import classes from './RegSuccess.module.scss';
import useClickHandlers from '../hooks/useClickHandlers';
import { useNavigate } from 'react-router-dom';
import { installationStore } from '@Store/installationStore';
import { useParams } from 'react-router-dom';
import { dashboardQuery } from 'features/dahboard/dashboard.query';
import { getUserSFDetails } from 'utils/getUserAccount';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { Button } from '@Components/Button';
import { instalationQuery } from './../Installation.query';
import texaIcon from './../../../assets/dashboardICons/texaIcon.svg';
import closeIcon from './../../../assets/closeIcon.svg';
import touchIcon from './../../../assets/touch_screen.png';
import { Link } from 'react-router-dom';
import { spinnerStore } from '@Store/spinnerStore';
import leftArrow from './../../../assets/LeftArrow.svg';
import rightArrow from './../../../assets/RightArrowClick.svg';
function RegSuccess() {
  const navigate = useNavigate();
  const {
    locationDetails,
    installationType,
    selectedEquipmentId,
    equipmentTypes,
    setNextButton,
    setInstallationDetailsData,
    installationDetails,
  } = installationStore();
  const {
    mutate,
    status,
    data: texaDetails,
  } = instalationQuery.useMutationtexaActivate();
  const userSFData = getUserSFDetails();
  const [cancelPopup, setCancelPopup] = React.useState(false);
  const [activatedPopup, setactivatedPopup] = React.useState(false);
  const [slide, setSlide] = React.useState(0);
  const [dataLength, setDataLength] = useState(false);
  const { setSpinner, isActive } = spinnerStore();
  const isLoading = status === 'loading';

  const { id } = useParams();
  const ids =
    localStorage.getItem('CheckedValues') ||
    localStorage.getItem('CheckedValue');
  const email = getUserSFDetails().email;
  // const { data } = dashboardQuery.useProduct(email, ids, true);
  const { data } = dashboardQuery.useMultiProducts(email, ids, true);
  const [successData, setSuccessData] = useState(data);

  const nextSlide = () => {
    setSlide(slide === data.length - 1 ? 0 : slide + 1);
  };
  const prevSlide = () => {
    setSlide(slide === 0 ? data.length - 1 : slide - 1);
  };
  useEffect(() => {
    processSuccessResponse(data);
    if (data?.length > 1) {
      setDataLength(true);
    } else {
      setDataLength(false);
    }
  }, [data]);
  function processSuccessResponse(data) {
    if (data) {
      const processSuccessData = data?.map((item) => {
        let processData = { ...item };
        for (let i = 0; i < installationDetails.length; i++) {
          const warrantyDetails = installationDetails[i];
          if (warrantyDetails.equipmentNumber === item.equipmentNumber) {
            processData = {
              ...item,
              hasextendedWarranty: warrantyDetails.hasextendedWarranty,
              identificationNum: warrantyDetails.identificationNum,
            };
            break;
          }
        }
        return processData;
      });
      setSuccessData(processSuccessData);
    } else {
      setSuccessData([]);
    }
  }
  useEffect(() => {
    setNextButton(false);
  }, []);
  const handleNextClick = () => {
    setTimeout(() => navigate('/dashboard'), 300);
  };
  useClickHandlers(handleNextClick);

  const eq = equipmentTypes?.find(
    (ele) => String(ele.id) === String(selectedEquipmentId),
  );
  const genrateStringByEqId = (idx) => {
    idx = String(idx);
    let string = '';
    if (idx === '1') {
      const { fullName, lastName } = installationType;
      string = fullName;
      string += lastName ? ', ' + lastName : '';
    }
    if (idx === '2') {
      const { name } = installationType;
      string = name;
    }

    if (idx === '3') {
      const { companyName } = installationType;
      string = companyName;
    }
    return string;
  };

  const getAddress = () => {
    const { address1, address2, city, state, country, zip } = locationDetails;
    return [address1, address2, city, state, country, zip]
      .filter((ele) => !!ele)
      .join(', ');
  };
  const Installer = genrateStringByEqId(selectedEquipmentId);
  const addressStr = getAddress();
  const endUserDetails = installationStore((state) => state.endUserDetails);

  const getCurrentDate = () => {
    const date = new Date();
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    const currentDate = `${year}-${month}-${day}`;
    return currentDate;
  };
  const handleTexaActivate = () => {
    const payload = {
      userId: userSFData.id,
      userEmail: userSFData.email,
      equipmentId: data[0]?.equipmentId,
      equipmentNumber: data[0]?.equipmentNumber,
      firstName: userSFData.firstName,
      lastName: userSFData.lastName,
    };
    mutate(payload);
    setactivatedPopup(true);
  };
  const handleClosed = () => {
    setCancelPopup(true);
    setactivatedPopup(false);
  };
  const activedCode = localStorage.getItem('activatedCode');

  useEffect(() => {
    if (isActive !== isLoading) {
      setSpinner(isLoading);
    }
  }, [isLoading, data, status]);
  return (
    <div>
      {endUserDetails?.id ? (
        <div>
          <div className={classes.regSuccessPage}>
            <div className={classes.regConfirmationCont}>
              {successData?.map((eq, idx) => (
                <div
                  key={idx}
                  className={
                    slide === idx ? classes.slide : classes.slide_hidden
                  }
                >
                  <div className={classes.equipDetails}>
                    {dataLength ? (
                      <button onClick={prevSlide} className={classes.arrow}>
                        <img src={leftArrow} alt="leftArrow" />
                      </button>
                    ) : null}
                    <h2>Equipment Registration Details</h2>

                    <div className={classes.SerailsNumber}>
                      <div className={classes.equipDetailsList}>
                        <p className={classes.equipSubHead}>Product Name</p>
                        <p className={classes.equipSubHeadDetail}>
                          {eq?.productName}
                        </p>
                      </div>
                      <div className={classes.equipDetailsList}>
                        <p className={classes.equipSubHead}>Product Code</p>
                        <p className={classes.equipSubHeadDetail}>
                          {eq?.productCode}
                        </p>
                      </div>
                    </div>
                    <div className={classes.SerailsNumber}>
                      <div className={classes.equipDetailsList}>
                        <p className={classes.equipSubHead}>Distributor</p>
                        <p className={classes.equipSubHeadDetail}>
                          {eq?.distributorName}
                        </p>
                      </div>
                      <div className={classes.equipDetailsList}>
                        <p className={classes.equipSubHead}>Serial Number</p>
                        <p className={classes.equipSubHeadDetail}>
                          {eq?.equipmentNumber}
                        </p>
                      </div>
                    </div>
                    <div className={classes.SerailsNumber}>
                      <div className={classes.equipDetailsList}>
                        <p className={classes.equipSubHead}>
                          Extended Warranty
                        </p>
                        <p className={classes.equipSubHeadDetail}>
                          {eq?.hasextendedWarranty ? 'YES' : 'NO'}
                        </p>
                      </div>
                      <div className={classes.equipDetailsList}>
                        <p className={classes.equipSubHead}>
                          Identification Number
                        </p>
                        <p className={classes.equipSubHeadDetail}>
                          {eq?.identificationNum}
                        </p>
                      </div>
                    </div>
                    {dataLength ? (
                      <button onClick={nextSlide} className={classes.arrow}>
                        <img src={rightArrow} alt="rightArrow" />
                      </button>
                    ) : null}
                  </div>
                </div>
              ))}

              <div className={classes.locationDetails}>
                <div className={classes.SerailsNumber}>
                  <div className={classes.locationDetailList}>
                    <p className={classes.equipSubHead}>Installer</p>
                    <p className={classes.equipSubHeadDetail}>{Installer}</p>
                  </div>
                  <div className={classes.locationDetailList}>
                    <p className={classes.equipSubHead}>Installer Type</p>
                    <p className={classes.equipSubHeadDetail}>
                      {eq?.installerType}
                    </p>
                  </div>
                </div>
                <div className={classes.SerailsNumber}>
                  <div className={classes.equipDetailsList}>
                    <p className={classes.equipSubHead}>Product Owner</p>
                    <p className={classes.equipSubHeadDetail}>
                      {endUserDetails.firstName} {endUserDetails.lastName}
                    </p>
                  </div>
                  <div className={classes.equipDetailsList}>
                    <p className={classes.equipSubHead}>Registered By</p>

                    <p className={classes.equipSubHeadDetail}>
                      {userSFData.firstName} {userSFData.lastName}
                    </p>
                  </div>
                </div>
                <div className={classes.SerailsNumber}>
                  <div className={classes.locationDetailList}>
                    <p className={classes.equipSubHead}>
                      Address of the Equipment
                    </p>
                    <p className={classes.equipSubHeadDetail}>{addressStr}</p>
                  </div>
                  <div className={classes.locationDetailList}>
                    <p className={classes.equipSubHead}>Date of Registration</p>
                    <p className={classes.equipSubHeadDetail}>
                      {getCurrentDate()}
                    </p>
                  </div>
                </div>
                <div className={classes.SerailsNumber}>
                  <div className={classes.locationDetailList}>
                    <p className={classes.equipSubHead}>Email</p>
                    <p className={classes.equipSubHeadDetail}>
                      {endUserDetails.email || userSFData.email || 'N/A'}
                    </p>
                    {/* <p className={classes.hoverText}>
                      <p className={classes.rotaryTitle}>
                        {locationDetails?.email}
                      </p>
                      <span className={classes.tooltip}>
                        {locationDetails?.email}
                      </span>
                    </p> */}
                  </div>
                  <div className={classes.locationDetailList}>
                    <p className={classes.equipSubHead}>Phone</p>
                    <p className={classes.equipSubHeadDetail}>
                      {locationDetails?.phoneNumber || 'N/A'}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className={classes.regSuccessPage}>
          <div className={classes.regConfirmationCont}>
            {/* <h2>Equipment Registration Details'ss</h2> */}
            {successData?.map((eq, idx) => (
              <div
                key={idx}
                className={slide === idx ? classes.slide : classes.slide_hidden}
              >
                <div className={classes.equipDetails}>
                  {dataLength ? (
                    <button onClick={prevSlide} className={classes.arrow}>
                      <img src={leftArrow} alt="leftArrow" />
                    </button>
                  ) : null}
                  <h2>Equipment Registration Details</h2>
                  <div className={classes.equipDetails}>
                    <div className={classes.equipDetailsList}>
                      <p className={classes.equipSubHead}>Product Name:</p>
                      <p className={classes.equipSubHeadDetail}>
                        {eq?.productName}
                      </p>
                    </div>
                    <div className={classes.SerailsNumber}>
                      <div className={classes.equipDetailsList}>
                        <p className={classes.equipSubHead}>Product Code:</p>
                        <p className={classes.equipSubHeadDetail}>
                          {eq?.productCode}
                        </p>
                      </div>
                      <div className={classes.equipDetailsList}>
                        <p className={classes.equipSubHead}>Serial Number:</p>
                        <p className={classes.equipSubHeadDetail}>
                          {eq?.equipmentNumber}
                        </p>
                      </div>
                    </div>
                    <div className={classes.SerailsNumber}>
                      <div className={classes.equipDetailsList}>
                        <p className={classes.equipSubHead}>Installer Type:</p>
                        <p className={classes.equipSubHeadDetail}>
                          {eq?.installerType}
                        </p>
                      </div>
                      <div className={classes.equipDetailsList}>
                        <p className={classes.equipSubHead}>Installer:</p>
                        <p className={classes.equipSubHeadDetail}>
                          {Installer}
                        </p>
                      </div>
                    </div>
                    <div className={classes.SerailsNumber}>
                      <div className={classes.equipDetailsList}>
                        <p className={classes.equipSubHead}>
                          Extended Warranty
                        </p>
                        <p className={classes.equipSubHeadDetail}>
                          {eq?.identificationNum ? 'YES' : 'NO'}
                        </p>
                      </div>
                      <div className={classes.equipDetailsList}>
                        <p className={classes.equipSubHead}>
                          Identification Number
                        </p>
                        <p className={classes.equipSubHeadDetail}>
                          {eq?.identificationNum}
                        </p>
                      </div>
                    </div>
                  </div>
                  {dataLength ? (
                    <button onClick={nextSlide} className={classes.arrow}>
                      <img src={rightArrow} alt="rightArrow" />
                    </button>
                  ) : null}
                </div>
              </div>
            ))}
            <div className={classes.locationDetails}>
              <div className={classes.locationDetailList}>
                <p className={classes.equipSubHead}>
                  Address of the Equipment:
                </p>
                <p className={classes.equipSubHeadDetail}>{addressStr}</p>
              </div>
              <div className={classes.SerailsNumber}>
                <div className={classes.locationDetailList}>
                  <p className={classes.equipSubHead}>Email:</p>
                  <p className={classes.equipSubHeadDetail}>
                    {endUserDetails.email || userSFData.email || 'N/A'}
                  </p>
                  {/* <p className={classes.hoverText}>
                    <p className={classes.rotaryTitle}>
                      {locationDetails?.email}
                    </p>
                    <span className={classes.tooltip}>
                      {locationDetails?.email}
                    </span>
                  </p> */}
                </div>
                <div className={classes.locationDetailList}>
                  <p className={classes.equipSubHead}>Phone:</p>
                  <p className={classes.equipSubHeadDetail}>
                    {locationDetails?.phoneNumber || 'N/A'}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {data[0].isTexaProduct === true &&
      data[0].isTexaProductActivated === false ? (
        <>
          <div>
            <Modal
              open={
                true &&
                // data.isTexaProduct === true &&
                // data.isTexaProductActivated === false
                // texaDetails?.data?.response !== 1 &&
                cancelPopup === false
              }
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box className={classes.ModalStyle}>
                <Typography
                  id="modal-modal-title"
                  variant="h6"
                  component="h2"
                  className={classes.modalTitle}
                >
                  Equipment is Inactive
                </Typography>
                <div style={{ textAlign: 'center', marginTop: '25px' }}>
                  <img src={texaIcon} alt="TexaIcon" />
                </div>
                <Typography
                  id="modal-modal-description"
                  sx={{ mt: 2 }}
                  // className={classes.paraGraph}
                  className={classes.texaInactiveText}
                >
                  <div>
                    Your equipment is registered but not activated. Please
                    activate it.
                  </div>
                </Typography>

                <div className={classes.popupActiveButtons}>
                  <Button
                    variant="outlined"
                    color="secondary"
                    onClick={() => setCancelPopup(true)}
                    className={classes.cancelButton}
                  >
                    Activate Later
                  </Button>
                  <Button
                    className={classes.popupActiveButton}
                    type="submit"
                    onClick={() => {
                      handleTexaActivate();
                    }}
                  >
                    Activate Now
                  </Button>
                </div>
              </Box>
            </Modal>
          </div>
          <div>
            <Modal
              open={
                activatedPopup === true && texaDetails?.data?.response !== 1
              }
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box className={classes.ModalStyle}>
                <Typography
                  id="modal-modal-title"
                  variant="h6"
                  component="h2"
                  className={classes.modalTitle}
                >
                  Activation Code
                  <Link onClick={handleClosed} className={classes.closeIcon}>
                    <img src={closeIcon} alt="closeIcon" />
                  </Link>
                </Typography>

                <Typography
                  id="modal-modal-description"
                  sx={{ mt: 2 }}
                  className={classes.paraGraphcont}
                >
                  <div className={classes.texaInactiveText}>
                    <div>
                      <img src={touchIcon} alt="Touch Icon" />
                    </div>
                    <div className={classes.texaActivatedText}>
                      <p>
                        Your Equipment has been activated from our end, Please
                        enter the activation code below during equipment set up
                      </p>
                    </div>
                    <div className={classes.texaActivatedCode}>
                      {activedCode}
                    </div>
                    <span className={classes.texaActivatedCodeSpan}>
                      An email has also been sent with the activation code to
                      your registered email
                    </span>
                  </div>
                </Typography>
              </Box>
            </Modal>
          </div>
        </>
      ) : null}
    </div>
  );
}

export default RegSuccess;
