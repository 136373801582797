import React, { useState, useEffect } from 'react';
import classes from './CreateOwner.module.scss';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Button } from '@Components/Button';
import { Link } from 'react-router-dom';
import closeIcon from './../../../../../assets/closeIcon.svg';
import { useFormHook, f } from './CreateOwner.form.initializer';
import { MappedInput } from '@Components/Input';
import { instalationQuery } from 'features/Installation/Installation.query';
import { useNavigate } from 'react-router-dom';
import { InputController } from '@Components/Input/MappedInput';
import DropdownField from '@Components/Input/Dropdown';
import { spinnerStore } from '@Store/spinnerStore';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
function CreateOwner({ open, setOpen, values, setEndUserStatus }) {
  const { mutate, status, data } = instalationQuery.useMutationCreateUser();
  const [countryIdData, setCountryIdData] = useState('');
  const [stateIdData, setStateIdData] = useState([]);
  const { data: countryData } = instalationQuery.useCountriesList(true);
  const { data: stateData } = instalationQuery.useStateCode(
    countryIdData,
    true,
  );
  const url = window.location.pathname;
  const number = url.split('/').pop();

  useEffect(() => {
    if (status === 'success' || status === 'error') {
      setEndUserStatus(status);
      setOpen(false);
    }
  }, [status]);
  const style = {
    position: 'absolute',
    top: '0',
    left: '0',
    bottom: '0',
    width: '100%',
    bgcolor: 'background.paper',
    boxShadow: 24,
    '@media (min-width: 768px)': {
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: '667px',
      // height: '700px',
      height: '730px',
    },
    '@media (min-width: 1024px)': {
      height: '570px !important',
    },
    '@media (orientation: landscape)': {
      // backgroundColor: '#000',
    },
    '@media (min-width:769px) and (min-width:900px)': {
      width: '880px',
      height: '550px',
      overflowY: 'auto',
    },
    '@media (min-width:900px)': {
      width: '910px',
      height: '439px',
    },
  };
  const modalTitle = {
    backgroundColor: '#000',
    color: '#fff',
    padding: '5px 20px 5px 20px ',
    display: 'flex',
    justifyContent: 'space-between',
  };
  const paraGraph = {
    margin: '0px',
  };
  const handleClosePop = () => setOpen(false);
  const navigate = useNavigate();
  const { setSpinner, isActive } = spinnerStore();

  const countryCode = '+1';
  const onSubmit = (values, event, actions) => {
    const BillingCity = values.BillingCity;
    const BillingCountryName = values.BillingCountryName;
    const BillingStateName = values.BillingStateName;
    const BillingStreet = values.BillingStreet;
    const LastName = values.LastName;
    const PhoneNumber = values.PhoneNumber?.replace(/[^\d]/g, '');
    const ZipCode = values.ZipCode;
    const email = values.email;
    const firstName = values.firstName;
    const BillingCountryId = countryIdData;
    const BillingStateId = stateIdData;
    mutate({
      BillingCity,
      BillingCountryName,
      BillingStateName,
      BillingStreet,
      LastName,
      PhoneNumber,
      ZipCode,
      email,
      firstName,
      countryCode,
      BillingCountryId,
      BillingStateId,
    });

    // mutate({ ...values, countryCode, BillingCountryId, BillingStateId });
  };

  const handleButton = () => {
    if (status === 'success') {
      setOpen(false);
      setTimeout(() => {
        // navigate(`/installation/${number}`);
        navigate(`/installation`);
      }, 100);
    } else {
      setOpen(true);
    }
  };

  const form = useFormHook(onSubmit);
  useEffect(() => {
    if (countryData?.length) {
      const options = countryData?.map((ele) => {
        return {
          label: ele.countryName,
          value: ele.countryName,
        };
      });
      f.rowThree.forEach((i) => {
        if (i.id === 'BillingCountryName') {
          i.options = options;
        }
      });
    }
  }, [countryData]);
  useEffect(() => {
    if (stateData?.length) {
      const options = stateData?.map((ele) => {
        return {
          label: ele.stateName,
          value: ele.stateName,
        };
      });
      f.rowFour[0].options = options;
      form.setValues({ ...form.values });
    }
  }, [stateData]);
  useEffect(() => {
    countryData?.map((i) => {
      if (form.values.BillingCountryName === i.countryName) {
        setCountryIdData(i.countryId);
      }
    });
  }, [countryData, form]);
  useEffect(() => {
    stateData?.map((i) => {
      if (form.values.BillingStateName === i.stateName) {
        setStateIdData(i.stateId);
      }
    });
  }, [stateData, form]);
  useEffect(() => {
    form.setFieldValue('email', values.values.endUserEmail);
  }, []);
  const [countryValue, setCountryValue] = useState('');
  const onCountryChange = (e) => {
    form.setFieldValue('BillingStateName', '');
    form.setFieldValue('BillingCountryName', e.target.value);
    setCountryValue(e.target.value);
  };
  const isLoading = status === 'loading';
  useEffect(() => {
    if (isActive !== isLoading) {
      setSpinner(isLoading);
    }
  }, [isLoading]);
  const [dropdownClicked, setDropdownClicked] = useState(false);
  const [stateValueCheck, setStateValueCheck] = useState(false);
  const handleState = (e) => {
    form.setFieldValue('BillingStateName', e.target.value);
    // alert('hello');
    // form.handleChange(e);
  };
  const handleStateClick = (event) => {
    // Set the temporary value to what is currently selected
    // setTempValue(event.target.value);
    if (countryValue !== '') {
      handleState();
      setStateValueCheck(false);
      setDropdownClicked(true);
    } else {
      form.setFieldValue('state', '');
      setStateValueCheck(true);
      setDropdownClicked(false);
      // alert('emptyCountry');
    }
  };
  const handleClose = () => {
    setStateValueCheck(false);
  };
  const handleCountryClick = () => {};
  return (
    <div>
      <div>
        <Modal
          open={open}
          onClose={handleClosePop}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style} className={classes.RegisterEquipt_Modal}>
            <p style={modalTitle}>
              <p className={classes.alignItems}>
                <p>
                  <Typography
                    id="modal-modal-title"
                    variant="h6"
                    component="h2"
                    className={classes.textFontSize}
                  >
                    Create User
                  </Typography>
                </p>
              </p>
              <p className={classes.iconStyles}>
                <Link onClick={handleClosePop} className={classes.closeIcon}>
                  <img src={closeIcon} alt="closeIcon" />
                </Link>
              </p>
            </p>
            <Typography
              id="modal-modal-description"
              sx={{ mt: 2 }}
              component="div"
              style={paraGraph}
            >
              <div className={classes.NewLocaitonCont}>
                <form
                  onSubmit={form.handleSubmit}
                  style={{ padding: '0px 20px 0px 20px' }}
                >
                  <div className={classes.NewLocationFormCont}>
                    <div className={classes.feildrow}>
                      <MappedInput fields={f['rowOne']} form={form} />
                    </div>
                    <div className={classes.feildrow}>
                      <MappedInput fields={f['rowTwo']} form={form} />
                    </div>
                    <div className={classes.feildrow}>
                      <MappedInput fields={f['rowSix']} form={form} />
                    </div>
                    <div className={classes.headingStyles}>Billing Address</div>
                    <div className={classes.feildrow}>
                      <InputController el={f['rowThree'][0]} form={form} />
                      {f['rowThree'][1]?.name && (
                        <DropdownField
                          {...{
                            ...f['rowThree'][1],
                            key: `${f['rowThree'][1].name}`,
                            onChange: onCountryChange,
                            onClick: handleCountryClick,
                            value: form.values[f['rowThree'][1].name],
                            errors: form.errors[f['rowThree'][1].name],
                            name: f['rowThree'][1].name,
                            touched: form.touched[f['rowThree'][1].name],
                          }}
                        />
                      )}
                    </div>
                    <div className={classes.feildrow}>
                      {f['rowFour'][0]?.name && (
                        <DropdownField
                          {...{
                            ...f['rowFour'][0],
                            key: `${f['rowFour'][0].name}`,
                            onClick: handleStateClick,
                            onChange: handleState,
                            value: form.values[f['rowFour'][0].name],
                            errors: form.errors[f['rowFour'][0].name],
                            name: f['rowFour'][0].name,
                            touched: form.touched[f['rowFour'][0].name],
                          }}
                        />
                      )}
                      <InputController el={f['rowFour'][1]} form={form} />
                      {/* <MappedInput fields={f['rowFour']} form={form} /> */}
                    </div>
                    <div className={classes.feildrow}>
                      <MappedInput fields={f['rowFive']} form={form} />
                    </div>
                    <div className={classes.createAccount}>
                      <Button
                        type="submit"
                        disabled={!form.isValid || !form.dirty}
                      >
                        Create User
                      </Button>
                    </div>
                  </div>
                  <Dialog
                    open={stateValueCheck}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                  >
                    <DialogContent>
                      <DialogContentText id="alert-dialog-description">
                        <p>Please select a country before choosing a state.</p>
                      </DialogContentText>
                    </DialogContent>
                  </Dialog>
                </form>
              </div>
            </Typography>
          </Box>
        </Modal>
      </div>
    </div>
  );
}
export default CreateOwner;
