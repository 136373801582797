import React, { useEffect, useState } from 'react';
import classes from './CsrSuccess.module.scss';
import useClickHandlers from '../hooks/useClickHandlers';
import { useNavigate } from 'react-router-dom';
import { installationStore } from '@Store/installationStore';
import { useParams } from 'react-router-dom';
import { dashboardQuery } from 'features/dahboard/dashboard.query';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Link } from 'react-router-dom';
import { Button } from '@Components/Button';
import { getUserSFDetails } from 'utils/getUserAccount';
import { spinnerStore } from '@Store/spinnerStore';
function CsrRegSuccess() {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const { setNextButton } = installationStore();
  useEffect(() => {
    setNextButton(false);
  }, []);
  useEffect(() => {
    const unloadCallback = (event) => {
      event.preventDefault();
      event.returnValue = '';
      return '';
    };

    window.addEventListener('beforeunload', unloadCallback);
    return () =>
      window.removeEventListener('dom.disable_beforeunload', unloadCallback);
  }, []);
  const style = {
    position: 'absolute',
    top: '0',
    left: '0',
    bottom: '0',
    width: '100%',
    bgcolor: 'background.paper',
    boxShadow: 24,
    // Adding media query..
    '@media (min-width: 768px)': {
      top: '40%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: '500px',
      height: '200px',
    },
    '@media (min-width: 768px) and (max-width: 915px) and (orientation:landscape)':
      {
        top: '55%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '500px',
        height: '284px',
      },
    '@media (min-width: 501px) and (max-width: 767px) ': {
      top: '57%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      maxWidth: '450px',
      height: '284px',
    },
    '@media (max-width: 500px)': {
      top: '40%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      maxWidth: '350px',
      marginTop: '9%',
      height: '284px',
    },
  };

  const handleNextClick = () => {
    setTimeout(() => navigate('/dashboard'), 300);
  };
  useClickHandlers(handleNextClick);

  const { id } = useParams();
  const email = getUserSFDetails().email;
  const ids =
    localStorage.getItem('CheckedValues') ||
    localStorage.getItem('CheckedValue');
  // const { data, isLoading } = dashboardQuery.useProduct(email, ids, true);
  // const { data } = dashboardQuery.useMultiProducts(email, ids, true);
  const { data, status, isLoading, isSuccess } =
    dashboardQuery.useMultiProducts(email, ids, true);
  const { setSpinner, isActive } = spinnerStore();
  const [dataLength, setDataLength] = useState(false);
  useEffect(() => {
    setSpinner(isLoading);
  }, [isLoading]);
  // useEffect(() => {
  //   if ((data[0]?.statusMessage === 'Unregistered', data[0]?.statusId === 2)) {
  //     setOpen(true);
  //   }
  // }, [data]);
  // const formatedDate = (data) => {
  //   return data[0]?.slice(0, 10);
  // };
  return (
    <>
      {isSuccess && data && (
        <div>
          {data[0]?.statusId === 1 &&
          data[0]?.statusMessage === 'Product Already Registered!!' ? (
            <div>
              <div className={classes.regSuccessPage}>
                <div className={classes.regConfirmationCont}>
                  <div className={classes.equipDetails}>
                    <h2>Equipment Registration Details</h2>

                    <div className={classes.SerailsNumber}>
                      <div className={classes.equipDetailsList}>
                        <p className={classes.equipSubHead}>Product Name</p>
                        <p className={classes.equipSubHeadDetail}>
                          {data[0]?.productName}
                        </p>
                      </div>
                      <div className={classes.equipDetailsList}>
                        <p className={classes.equipSubHead}>Product Owner</p>
                        <p className={classes.equipSubHeadDetail}>
                          {data[0]?.registeredFor}
                        </p>
                      </div>
                    </div>
                    <div className={classes.SerailsNumber}>
                      <div className={classes.equipDetailsList}>
                        <p className={classes.equipSubHead}>Product Code</p>
                        <p className={classes.equipSubHeadDetail}>
                          {data[0]?.productCode}
                        </p>
                      </div>
                      <div className={classes.equipDetailsList}>
                        <p className={classes.equipSubHead}>Serial Number</p>
                        <p className={classes.equipSubHeadDetail}>
                          {data[0]?.equipmentNumber}
                        </p>
                      </div>
                    </div>

                    <div className={classes.SerailsNumber}>
                      <div className={classes.locationDetailList}>
                        <p className={classes.equipSubHead}>Installer</p>
                        <p className={classes.equipSubHeadDetail}>
                          {data[0]?.installerName}
                        </p>
                      </div>
                      <div className={classes.locationDetailList}>
                        <p className={classes.equipSubHead}>Installer Type</p>
                        <p className={classes.equipSubHeadDetail}>
                          {data[0]?.installerType}
                        </p>
                      </div>
                    </div>
                    <div className={classes.SerailsNumber}>
                      <div className={classes.equipDetailsList}>
                        <p className={classes.equipSubHead}>Distributor</p>
                        <p className={classes.equipSubHeadDetail}>
                          {data[0]?.distributorName}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className={classes.locationDetails}>
                    <div className={classes.SerailsNumber}>
                      <div className={classes.locationDetailList}>
                        <p className={classes.equipSubHead}>
                          Address of the Equipment
                        </p>
                        <p className={classes.equipSubHeadDetail}>
                          {data[0]?.installedLocation}
                        </p>
                      </div>
                      <div className={classes.locationDetailList}>
                        <p className={classes.equipSubHead}>
                          Date of Registration
                        </p>
                        <p className={classes.equipSubHeadDetail}>
                          {data[0]?.installedLocation}
                        </p>
                      </div>
                    </div>
                    <div className={classes.SerailsNumber}>
                      <div className={classes.locationDetailList}>
                        <p className={classes.equipSubHead}>Email</p>
                        <p className={classes.hoverText}>
                          <p className={classes.rotaryTitle}>
                            {data[0]?.email || 'N/A'}
                          </p>
                          <span className={classes.tooltip}>
                            {data[0]?.email || 'N/A'}
                          </span>
                        </p>
                      </div>
                      <div className={classes.locationDetailList}>
                        <p className={classes.equipSubHead}>Phone</p>
                        <p className={classes.equipSubHeadDetail}>
                          {data?.phone || 'N/A'}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div>
              <Modal
                open={
                  (data?.statusId === 2 &&
                    data?.statusMessage === 'Unregistered') ||
                  data === null
                    ? true
                    : false
                }
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box className={classes.ModalStyle}>
                  {data === null ? (
                    <Typography
                      id="modal-modal-title"
                      variant="h6"
                      component="h2"
                      className={classes.modalTitle}
                    >
                      Equipment not found
                    </Typography>
                  ) : (
                    <Typography
                      id="modal-modal-title"
                      variant="h6"
                      component="h2"
                      className={classes.modalTitle}
                      // className={classes.titleInfo}
                    >
                      {data?.statusMessage}
                    </Typography>
                  )}
                  {data === null ? (
                    <Typography
                      id="modal-modal-description"
                      sx={{ mt: 2 }}
                      className={classes.paraGraph}
                    >
                      <div style={{ textAlign: 'center' }}>
                        <span style={{ fontWeight: '600' }}>
                          Equipment not found
                        </span>
                        , please click on back button to Register New Product
                      </div>
                    </Typography>
                  ) : (
                    <Typography
                      id="modal-modal-description"
                      sx={{ mt: 2 }}
                      className={classes.paraGraph}
                    >
                      <div style={{ textAlign: 'center' }}>
                        <span style={{ fontWeight: '600' }}>
                          Product is Unregistered
                        </span>
                        , please click on back button to Register New Product
                      </div>
                    </Typography>
                  )}
                  <div className={classes.verifyButton}>
                    <Link to="/Dashboard">
                      <Button variant="contained" color="primary">
                        Back
                      </Button>
                    </Link>
                  </div>
                </Box>
              </Modal>
            </div>
          )}
        </div>
      )}
    </>
  );
}

export default CsrRegSuccess;

// import React, { useEffect, useState } from 'react';
// import classes from './CsrSuccess.module.scss';
// import useClickHandlers from '../hooks/useClickHandlers';
// import { useNavigate } from 'react-router-dom';
// import { installationStore } from '@Store/installationStore';
// import { useParams } from 'react-router-dom';
// import { dashboardQuery } from 'features/dahboard/dashboard.query';
// import Box from '@mui/material/Box';
// import Typography from '@mui/material/Typography';
// import Modal from '@mui/material/Modal';
// import { Link } from 'react-router-dom';
// import { Button } from '@Components/Button';
// import { getUserSFDetails } from 'utils/getUserAccount';
// function CsrRegSuccess() {
//   const [open, setOpen] = useState(false);
//   const navigate = useNavigate();
//   const { setNextButton } = installationStore();
//   useEffect(() => {
//     setNextButton(false);
//   }, []);
//   useEffect(() => {
//     const unloadCallback = (event) => {
//       event.preventDefault();
//       event.returnValue = '';
//       return '';
//     };

//     window.addEventListener('beforeunload', unloadCallback);
//     return () =>
//       window.removeEventListener('dom.disable_beforeunload', unloadCallback);
//   }, []);
//   const style = {
//     position: 'absolute',
//     top: '0',
//     left: '0',
//     bottom: '0',
//     width: '100%',
//     bgcolor: 'background.paper',
//     boxShadow: 24,
//     // Adding media query..
//     '@media (min-width: 768px)': {
//       top: '40%',
//       left: '50%',
//       transform: 'translate(-50%, -50%)',
//       width: '500px',
//       height: '200px',
//     },
//     '@media (min-width: 768px) and (max-width: 915px) and (orientation:landscape)':
//       {
//         top: '55%',
//         left: '50%',
//         transform: 'translate(-50%, -50%)',
//         width: '500px',
//         height: '284px',
//       },
//     '@media (min-width: 501px) and (max-width: 767px) ': {
//       top: '57%',
//       left: '50%',
//       transform: 'translate(-50%, -50%)',
//       maxWidth: '450px',
//       height: '284px',
//     },
//     '@media (max-width: 500px)': {
//       top: '40%',
//       left: '50%',
//       transform: 'translate(-50%, -50%)',
//       maxWidth: '350px',
//       marginTop: '9%',
//       height: '284px',
//     },
//   };

//   const handleNextClick = () => {
//     setTimeout(() => navigate('/dashboard'), 300);
//   };
//   useClickHandlers(handleNextClick);

//   const { id } = useParams();
//   const email = getUserSFDetails().email;
//   const { data } = dashboardQuery.useProduct(email, id, true);

//   useEffect(() => {
//     setOpen(true);
//   }, [data?.statusMessage === 'Unregistered', data?.statusId === 2]);
//   const formatedDate = (data) => {
//     return data?.slice(0, 10);
//   };
//   return (
//     <div>
//       {data?.statusId === 1 &&
//       data?.statusMessage === 'Product Already Registered!!' ? (
//         <div>
//           <div className={classes.regSuccessPage}>
//             <div className={classes.regConfirmationCont}>
//               <div className={classes.equipDetails}>
//                 <h2>Equipment Registration Details</h2>

//                 <div className={classes.SerailsNumber}>
//                   <div className={classes.equipDetailsList}>
//                     <p className={classes.equipSubHead}>Product Name</p>
//                     <p className={classes.equipSubHeadDetail}>
//                       {data?.productName}
//                     </p>
//                   </div>
//                   <div className={classes.equipDetailsList}>
//                     <p className={classes.equipSubHead}>Product Owner</p>
//                     <p className={classes.equipSubHeadDetail}>
//                       {data?.registeredFor}
//                     </p>
//                   </div>
//                 </div>
//                 <div className={classes.SerailsNumber}>
//                   <div className={classes.equipDetailsList}>
//                     <p className={classes.equipSubHead}>Product Code</p>
//                     <p className={classes.equipSubHeadDetail}>
//                       {data?.productCode}
//                     </p>
//                   </div>
//                   <div className={classes.equipDetailsList}>
//                     <p className={classes.equipSubHead}>Serial Number</p>
//                     <p className={classes.equipSubHeadDetail}>
//                       {data?.equipmentNumber}
//                     </p>
//                   </div>
//                 </div>

//                 <div className={classes.SerailsNumber}>
//                   <div className={classes.locationDetailList}>
//                     <p className={classes.equipSubHead}>Installer</p>
//                     <p className={classes.equipSubHeadDetail}>
//                       {data?.installerName}
//                     </p>
//                   </div>
//                   <div className={classes.locationDetailList}>
//                     <p className={classes.equipSubHead}>Installer Type</p>
//                     <p className={classes.equipSubHeadDetail}>
//                       {data?.installerType}
//                     </p>
//                   </div>
//                 </div>
//                 <div className={classes.SerailsNumber}>
//                   <div className={classes.equipDetailsList}>
//                     <p className={classes.equipSubHead}>Distributor</p>
//                     <p className={classes.equipSubHeadDetail}>
//                       {data?.distributorName}
//                     </p>
//                   </div>
//                 </div>
//               </div>
//               <div className={classes.locationDetails}>
//                 <div className={classes.SerailsNumber}>
//                   <div className={classes.locationDetailList}>
//                     <p className={classes.equipSubHead}>
//                       Address of the Equipment
//                     </p>
//                     <p className={classes.equipSubHeadDetail}>
//                       {data?.installedLocation}
//                     </p>
//                   </div>
//                   <div className={classes.locationDetailList}>
//                     <p className={classes.equipSubHead}>Date of Registration</p>
//                     <p className={classes.equipSubHeadDetail}>
//                       {formatedDate(data?.registeredDate)}
//                     </p>
//                   </div>
//                 </div>
//                 <div className={classes.SerailsNumber}>
//                   <div className={classes.locationDetailList}>
//                     <p className={classes.equipSubHead}>Email</p>
//                     <p className={classes.hoverText}>
//                       <p className={classes.rotaryTitle}>
//                         {data?.email || 'N/A'}
//                       </p>
//                       <span className={classes.tooltip}>
//                         {data?.email || 'N/A'}
//                       </span>
//                     </p>
//                   </div>
//                   <div className={classes.locationDetailList}>
//                     <p className={classes.equipSubHead}>Phone</p>
//                     <p className={classes.equipSubHeadDetail}>
//                       {data?.phone || 'N/A'}
//                     </p>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       ) : (
//         <div>
//           <Modal
//             open={
//               (data?.statusId === 2 &&
//                 data?.statusMessage === 'Unregistered') ||
//               data === null
//                 ? true
//                 : false
//             }
//             aria-labelledby="modal-modal-title"
//             aria-describedby="modal-modal-description"
//           >
//             <Box className={classes.ModalStyle}>
//               {data === null ? (
//                 <Typography
//                   id="modal-modal-title"
//                   variant="h6"
//                   component="h2"
//                   className={classes.modalTitle}
//                 >
//                   Equipment not found
//                 </Typography>
//               ) : (
//                 <Typography
//                   id="modal-modal-title"
//                   variant="h6"
//                   component="h2"
//                   className={classes.modalTitle}
//                   // className={classes.titleInfo}
//                 >
//                   {data?.statusMessage}
//                 </Typography>
//               )}
//               {data === null ? (
//                 <Typography
//                   id="modal-modal-description"
//                   sx={{ mt: 2 }}
//                   className={classes.paraGraph}
//                 >
//                   <div style={{ textAlign: 'center' }}>
//                     <span style={{ fontWeight: '600' }}>
//                       Equipment not found
//                     </span>
//                     , please click on back button to Register New Product
//                   </div>
//                 </Typography>
//               ) : (
//                 <Typography
//                   id="modal-modal-description"
//                   sx={{ mt: 2 }}
//                   className={classes.paraGraph}
//                 >
//                   <div style={{ textAlign: 'center' }}>
//                     <span style={{ fontWeight: '600' }}>
//                       Product is Unregistered
//                     </span>
//                     , please click on back button to Register New Product
//                   </div>
//                 </Typography>
//               )}
//               <div className={classes.verifyButton}>
//                 <Link to="/Dashboard">
//                   <Button variant="contained" color="primary">
//                     Back
//                   </Button>
//                 </Link>
//               </div>
//             </Box>
//           </Modal>
//         </div>
//       )}
//     </div>
//   );
// }

// export default CsrRegSuccess;
