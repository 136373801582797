import { useFormik } from 'formik';
import * as yup from 'yup';

const f = {
  rowOne: [
    {
      label: 'First Name',
      id: 'firstName',
      required: true,
      name: 'firstName',
      elementName: 'input',
      type: 'text',
      placeholder: 'Enter First Name',
    },
    {
      label: 'Last Name',
      id: 'LastName',
      required: true,
      name: 'LastName',
      elementName: 'input',
      type: 'text',
      placeholder: 'Enter Last Name',
    },
  ],
  rowTwo: [
    {
      label: 'Email Address',
      id: 'email',
      required: true,
      name: 'email',
      elementName: 'input',
      type: 'text',
      placeholder: 'Enter Email address here',
    },
    {
      label: 'Phone Number',
      id: 'PhoneNumber',
      required: true,
      name: 'PhoneNumber',
      // elementName: 'input',
      elementName: 'number',
      type: 'text',
      placeholder: 'Enter Phone Number here',
      // maxLength: '10',
    },
  ],
  rowThree: [
    {
      label: 'Street Address',
      id: 'BillingStreet',
      name: 'BillingStreet',
      elementName: 'input',
      type: 'text',
      required: true,
      placeholder: 'Enter Street Address',
    },
    {
      label: 'Country',
      id: 'BillingCountryName',
      required: true,
      name: 'BillingCountryName',
      elementName: 'dropdown',
      type: 'dropdown',
      placeholder: 'United States',
      options: [],
    },
  ],
  rowFour: [
    {
      label: 'State',
      id: 'BillingStateName',
      required: true,
      name: 'BillingStateName',
      elementName: 'dropdown',
      type: 'dropdown',
      placeholder: 'Enter State',
      options: [],
    },
    {
      label: 'City',
      id: 'BillingCity',
      required: true,
      name: 'BillingCity',
      elementName: 'input',
      type: 'text',
      placeholder: 'Enter City',
    },
  ],
  rowFive: [
    {
      label: 'ZIP Code',
      id: 'ZipCode',
      required: true,
      name: 'ZipCode',
      elementName: 'input',
      type: 'text',
      placeholder: 'Enter Zip Code ',
      maxLength: '7',
    },
  ],
  rowSix: [
    {
      label: 'Company Name',
      id: 'CompanyName',
      name: 'CompanyName',
      elementName: 'input',
      type: 'text',
      placeholder: 'Enter Company Name ',
      maxLength: '40',
    },
  ],
};

const validations = yup.object().shape({
  firstName: yup
    .string()
    .required('First Name Required!')
    .matches(/^[a-zA-Z\s]*$/, 'Please Enter a Valid First Name')
    .min(2, 'Enter First Name between 2-30 characters.')
    .max(30, 'Enter First Name between 2-30 characters.'),
  LastName: yup
    .string()
    .required('Last Name Required!')
    .matches(/^[a-zA-Z\s]*$/, 'Please Enter a Valid Last Name')
    .min(2, 'Enter Last Name between 2-30 characters.')
    .max(30, 'Enter Last Name between 2-30 characters.'),
  email: yup
    .string()
    .email('Please Enter a Valid Email')
    .matches(
      /^[a-zA-Z0-9_.-]+@[a-zA-Z]+\.[a-zA-Z]{2,5}$/,
      'Please Enter a Valid Email',
    )
    .required('Email Required!'),
  PhoneNumber: yup
    .string()
    // .matches(/^\d+$/, 'Please Enter a Valid PhoneNumber')
    // .min(10, 'Phone Number Should Contain Min is 10 and Max is 14')
    // .max(14, 'Phone Number Should Contain Min is 10 and Max is 14')
    .required('Phone Number Required!'),
  BillingStreet: yup
    .string()
    .required('Street Required!')
    .matches(
      /^(?=.*[a-zA-Z])[ ,-:#'.0-9a-zA-Z]*$/,
      'Numbers & Special Characters are not allowed!',
    ),
  BillingStateName: yup.string().required('State Name is Required!'),
  BillingCountryName: yup.string().required('Country Name is Required!'),
  BillingCity: yup
    .string()
    .required('City Name Required!')
    .matches(
      /^(?=.*[a-zA-Z])[ ,-:#'.0-9a-zA-Z]*$/,
      'Numbers & Special Characters are not allowed!',
    ),

  ZipCode: yup
    .string()
    .required('Zip Code Required!')
    .matches(/^(?=.*[0-9])[0-9a-zA-Z ]+$/, 'Please Enter a Valid Zip Code')
    .min(5, 'ZipCode Should Contain Min 5 & Max 7')
    .max(7, 'ZipCode Should Contain Min 5 & Max 7'),
  CompanyName: yup
    .string()
    .matches(
      /^(?=.*[a-zA-Z])[!@#$%^&*(),.?":{}|<>0-9a-zA-Z ;_+=~`'\\/\[\]\|\\:;'\"<,.>?-]+$/,
      'Please Enter a Valid Company Name',
    )
    .min(2, 'Enter Company Name between 2-30 characters.')
    .max(30, 'Enter Company Name between 2-30 characters.'),
});

const initialFormValues = {
  firstName: '',
  LastName: '',
  // BillingCountryName: 'United States',
  BillingCountryName: '',
  BillingCity: '',
  BillingStateName: '',
  // BillingStateName: 'New York',
  PhoneNumber: '',
  email: '',
  ZipCode: '',
  BillingStreet: '',
};
const useFormHook = (
  onSubmit,
  initialValues = initialFormValues,
  validationSchema = validations,
) => {
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  });

  return formik;
};

export { f, validations, useFormHook };
